import React, { useState } from "react";
import { Switch } from "@material-tailwind/react";
import BackButton from "../backButton/BackButton";

const carDamages = [
  {
    image: "./damage-example/image.png",
    toggled: true,
    price: 300,
    title: "Front Bumper",
  },
  {
    image: "./damage-example/image.png",
    toggled: true,
    price: 100,
    title: "Front Bumper",
  },
  {
    image: "./damage-example/image.png",
    toggled: false,
    price: 100,
    title: "Front Bumper",
  },
];
const currency = "€";

function FixingSection({ goBack: goBack }) {
  const [damages, setDamages] = useState(carDamages);
  const handleToggle = (index) => {
    setDamages((prevDamages) =>
      prevDamages.map((damage, i) =>
        i === index ? { ...damage, toggled: !damage.toggled } : damage
      )
    );
  };
  return (
    <div className="pb-20">
      <BackButton handleBack={goBack} />

      <div className="w-full max-w-md mx-auto mt-10 p-2 min-h-[500px]">
        <h1 className="text-2xl font-bold">
          Click to Add fixing damage to the price
        </h1>
        <small className="text-center mt-4">Click on photo to add price</small>

        <div className="pb:5">
          {damages.map(({ toggled, title, image, price }, index) => (
            <div
              key={index}
              className={`flex flex-col sm:flex-row  sm:flex-wrap items-center justify-between mt-4 p-5 rounded-lg ${
                !toggled ? "bg-gray-300 opacity-50" : "bg-lightwhiteblue"
              }`}
            >
              <div className="w-full flex justify-end mb-2">
                <Switch
                  color="blue"
                  defaultChecked={toggled}
                  onChange={() => handleToggle(index)}
                />
              </div>
              <div>
                <img src={image} alt={title} />
              </div>
              <div className="flex justify-between w-full mt-5">
                <h4 className="font-bold">{title}</h4>
                <h4>
                  {price} {currency}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FixingSection;
