import { useMemo } from "react";
import { Popover } from "@headlessui/react";
import internalImage from "./../../../../Assets/car-images/internal_car.png";
import { CarInitialStates } from "../../../../Constants/cars";

export const InternalState = ({ images }) => {
  const carInitialStates = useMemo(
    () =>
      CarInitialStates.reduce((uploadedImages, item) => {
        const damagedImages = images.filter(
          (file) => file.filename?.includes(item.name) && !!file.image_link
        );
        return damagedImages?.length
          ? [...uploadedImages, { ...item, images: damagedImages }]
          : uploadedImages;
      }, []),
    [images]
  );

  return (
    <div className="flex justify-center mt-10">
      <div className="relative">
        <img src={internalImage} alt="internal-state" />
        {carInitialStates.map(
          ({ className, images, panelClassName }, index) => {
            return (
              <Popover key={index} className={`absolute ${className}`}>
                <Popover.Button>
                  <div className="bg-[#282F75] w-[10px] h-[10px] rounded-full" />
                </Popover.Button>
                <Popover.Panel
                  className={`absolute z-10 bg-white0 p-2 rounded-lg drop-shadow-md w-[200px] ${
                    panelClassName ? panelClassName : ""
                  }`}
                >
                  <img
                    className="max-h-[200px] max-w-[200px]"
                    src={images && images[0]?.image_link}
                    alt="uploaded_image"
                  />
                </Popover.Panel>
              </Popover>
            );
          }
        )}
      </div>
    </div>
  );
};
