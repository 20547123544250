import React from "react";
import ConfigureCampaign from "./ConfigureCampaign";
import TargetPopulation from "./TargetPopulation";
import BudgetConfigure from "./BudgetConfigure";
import Insight from "./Insight";
import Published from "./Published";

function BaseStepper({
  activeStep,
  chosenConfiguration,
  handleOpenChooseConfigurationModal,
  handleGoToAnalytics,
}) {
  const renderStepper = () => {
    switch (activeStep) {
      case 1:
        return <ConfigureCampaign />;
      case 2:
        return (
          <TargetPopulation
            chosenConfiguration={chosenConfiguration}
            handleOpenChooseConfigurationModal={
              handleOpenChooseConfigurationModal
            }
          />
        );
      case 3:
        return <BudgetConfigure />;
      case 4:
        return <Insight />;
      case 5:
        return <Published handleGoToAnalytics={handleGoToAnalytics} />;
      default:
        return <ConfigureCampaign />;
    }
  };
  return (
    <div>
      <div className="flex flex-col justify-center items-center gap-2 mb-4">
        <h1 className="font-bold text-xxl">
          {activeStep < 5
            ? "Configurer votre compagne de Marketing"
            : "Compagne crée avec succès"}
        </h1>
        <p className="text-center w-[50%] text-textgrey1 text-sm">
          {activeStep < 5
            ? "Cette configuration est nécessaire et sera appliquée lors de l’activation du sponsorship de votre campagne"
            : "Vous pouvez aller dans Analytiques pour suivre votre campagne, la mettre en pause ou l’arrêter"}
        </p>
      </div>
      <div className="rounded bg-white border">{renderStepper()}</div>
    </div>
  );
}

export default BaseStepper;
