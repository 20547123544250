import React from "react";
import MultiRangeSlider from "../multiRangeSlider/MultiRangeSlider";

function PreviewSection() {
  return (
    <div className="rounded bg-white flex-col">
      <div className="w-full bg-gray-100 h-0.5 mb-2"></div>
      <div className=" rounded-b overflow-hidden">
        <div className="p-2">
          <p className="text-warning1 text-xl font-semibold">Car Price</p>
          <div className="flex items-center justify-start mt-2 ">
            <p className="text-black text-l m-2">Average price:</p>
            <p className="text-darkblue1 text-xl m-2 font-bold">€ 28,000</p>
          </div>
          <div className="rounded-lg">
            <img
              className="rounded"
              src="./car-pricing-example/image.png"
              alt="car"
            />
          </div>
          <div>
            <MultiRangeSlider min={0} max={100} />
          </div>
          <ul class="plus-list">
            <li>
              <div className="flex items-center justify-between mt-2 px-10">
                <span class="text-black">+ Exterior major damage</span>
                <p className="text-black text-lg m-2">300 €</p>
              </div>
            </li>
            <li>
              <div className="flex items-center justify-between mt-2 px-10">
                <span class="text-black">+ Exterior major damage</span>
                <p className="text-black text-lg m-2">300 €</p>
              </div>
            </li>
          </ul>

          <div className="flex items-center justify-between mt-2 px-20 ">
            <p className="text-darkblue1 text-xl m-2 font-bold">Total</p>
            <p className="text-darkblue1 text-xl font-bold">€ 28,000</p>
          </div>

          <div className="px-10 my-5">
            <small className=" text-sm text-lightblue2">
              *nb: The Fiat Punto was discontinued in 2018, so you can only buy
              used Fiat Puntos in France in 2023. The average price of a used
              Fiat Punto in France in 2023 is between €2,000 and €5,000. The
              price will vary depending on the year, mileage, condition, and
              location of the car.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewSection;
