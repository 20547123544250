import React from "react";

function CarLinedIcon() {
  return (
    <svg
      width="175"
      height="333"
      viewBox="0 0 175 333"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_441_19283)">
        <path
          d="M124.286 18.7171C124.286 14.6966 124.456 11.5173 124.569 9.84721C124.589 9.73718 124.654 9.63713 124.751 9.56494C124.849 9.49275 124.972 9.45312 125.1 9.45312C125.227 9.45312 125.351 9.49275 125.448 9.56494C125.545 9.63713 125.61 9.73718 125.63 9.84721C125.319 14.4739 124.555 30.6427 128.249 44.5723C131.781 58.0053 138.011 70.7843 146.649 82.316C146.728 82.4177 146.757 82.5426 146.731 82.6632C146.704 82.7839 146.624 82.8904 146.507 82.9593C146.391 83.0282 146.248 83.0538 146.11 83.0306C145.972 83.0074 145.85 82.9372 145.771 82.8355C137.064 71.2111 130.791 58.3264 127.244 44.7826C125.178 36.2138 124.186 27.4738 124.286 18.7171Z"
          fill="black"
        />
        <path
          d="M130.274 12.804C130.274 12.7259 130.296 12.6491 130.338 12.5801C130.38 12.5112 130.441 12.4521 130.515 12.4081C130.597 12.3632 130.693 12.3394 130.791 12.3394C130.888 12.3394 130.984 12.3632 131.067 12.4081C131.194 12.4081 143.154 18.5935 150.656 26.7088C155.114 31.5582 155.086 40.8117 154.902 44.5601C154.822 45.8952 154.176 47.1564 153.09 48.0981L150.656 50.2259C150.58 50.2984 150.479 50.3464 150.369 50.3621C150.258 50.3778 150.145 50.3604 150.047 50.3125C149.946 50.2706 149.863 50.2005 149.812 50.1133C149.761 50.0261 149.744 49.9265 149.764 49.8301C149.764 49.694 152.213 35.987 145.136 30.4944C144.115 29.7043 142.995 29.0194 141.795 28.4531C139.765 27.4884 137.932 26.2341 136.374 24.7419C131.619 20.4739 130.373 13.1874 130.316 12.8782L130.274 12.804ZM153.869 41.9003C153.869 37.5953 153.246 31.0016 149.821 27.2779C143.706 20.6224 134.563 15.4142 131.576 13.7318C132.086 15.8719 133.614 20.9316 137.124 24.0862C138.616 25.5103 140.367 26.7099 142.305 27.6367C143.534 28.2416 144.683 28.9635 145.73 29.7892C151.944 34.6139 151.392 44.8817 150.896 48.6054L152.227 47.3683C153.129 46.5895 153.664 45.5433 153.727 44.4364C153.84 43.8673 153.869 42.9147 153.869 41.9003Z"
          fill="black"
        />
        <path
          d="M86.9771 2.47433C86.9807 2.35392 87.037 2.23931 87.1345 2.15414C87.2319 2.06896 87.363 2.01973 87.5008 2.0166C110.317 2.0166 126.722 9.52574 131.123 12.4329C131.178 12.4695 131.224 12.5151 131.259 12.5672C131.294 12.6193 131.316 12.6769 131.325 12.7366C131.335 12.7963 131.33 12.8571 131.313 12.9153C131.295 12.9736 131.264 13.0283 131.222 13.0762C131.181 13.1241 131.128 13.1644 131.069 13.1947C131.009 13.225 130.943 13.2447 130.875 13.2527C130.807 13.2608 130.737 13.257 130.671 13.2415C130.604 13.2261 130.541 13.1993 130.486 13.1628C130.331 13.0638 114.209 2.94442 87.5008 2.94442C87.3606 2.94119 87.2274 2.89025 87.1296 2.80244C87.0318 2.71463 86.9771 2.59689 86.9771 2.47433Z"
          fill="black"
        />
        <path
          d="M124.174 7.62049C124.174 7.62049 124.174 6.79165 124.358 6.11125C124.382 5.99063 124.459 5.88305 124.573 5.81143C124.686 5.7398 124.828 5.70977 124.966 5.72775C125.035 5.73674 125.102 5.75774 125.162 5.78953C125.221 5.82131 125.273 5.86324 125.314 5.91282C125.355 5.9624 125.384 6.01863 125.4 6.07821C125.415 6.13778 125.417 6.19949 125.405 6.25969C125.405 6.65556 125.292 7.08855 125.264 7.37308C130.686 9.91931 135.945 12.7235 141.017 15.7729L141.88 16.3049C145.815 18.7791 148.193 20.177 148.957 19.6945C149.223 19.1225 149.306 18.4983 149.198 17.8883C149.19 17.7656 149.238 17.6451 149.33 17.5526C149.423 17.4601 149.553 17.403 149.693 17.3935C149.834 17.3867 149.972 17.4282 150.077 17.5091C150.183 17.59 150.249 17.704 150.259 17.8265C150.415 19.7687 149.962 20.3007 149.608 20.4615C148.193 21.3027 146.155 20.0656 141.272 17.0595L140.408 16.5399C135.294 13.43 129.983 10.576 124.499 7.99162C124.412 7.96106 124.335 7.91045 124.278 7.84514C124.221 7.77983 124.185 7.70222 124.174 7.62049Z"
          fill="black"
        />
        <path
          d="M86.9756 0.457727C86.9791 0.337318 87.0355 0.222696 87.1329 0.137525C87.2304 0.0523538 87.3615 0.00312908 87.4993 0C118.78 0 152.31 12.8534 159.274 27.5005C160.745 30.9074 161.609 34.4913 161.836 38.1271C162.714 47.2816 161.836 66.4194 161.242 81.7964C160.874 90.4561 160.548 97.8786 160.548 102.567C160.55 102.628 160.538 102.689 160.512 102.746C160.487 102.803 160.449 102.855 160.4 102.899C160.351 102.943 160.293 102.977 160.228 103.001C160.164 103.025 160.094 103.037 160.024 103.037C159.882 103.037 159.745 102.988 159.644 102.9C159.543 102.811 159.486 102.692 159.486 102.567C159.486 97.8786 159.812 90.4066 160.18 81.7593C160.831 66.407 161.595 47.294 160.774 38.2137C160.558 34.6708 159.718 31.1782 158.283 27.8593C151.603 13.7564 117.86 0.927818 87.5134 0.927818C87.3708 0.927818 87.234 0.878292 87.1331 0.790133C87.0322 0.701973 86.9756 0.582404 86.9756 0.457727Z"
          fill="black"
        />
        <path
          d="M153.033 99.8704C153.032 99.7981 153.05 99.7266 153.087 99.6619C153.124 99.5973 153.178 99.5414 153.246 99.4993C153.316 99.4537 153.398 99.4241 153.484 99.4133C153.571 99.4025 153.659 99.4108 153.741 99.4374C154.194 99.5859 164.654 103.025 169.452 108.74C174.604 114.926 175 117.981 175 121.68C175.026 121.947 174.962 122.214 174.816 122.449C174.67 122.684 174.449 122.877 174.179 123.004C171.165 124.414 159.643 117.87 157.365 116.546C157.258 116.484 157.179 116.393 157.138 116.287C156.776 115.175 156.723 114.005 156.982 112.872C157.157 111.99 157.243 111.096 157.237 110.2C157.237 109.074 154.972 107.083 153.543 106.093C153.428 106.004 153.357 105.88 153.345 105.747L153.033 99.8951V99.8704ZM173.939 121.68C173.939 118.724 173.939 115.668 168.589 109.309C164.71 104.683 156.628 101.503 154.137 100.65L154.392 105.598C155.27 106.217 158.299 108.53 158.299 110.299C158.303 111.24 158.213 112.18 158.03 113.107C157.806 114.057 157.835 115.039 158.115 115.977C163.21 118.922 171.802 123.165 173.684 122.286C173.755 122.15 173.939 122.064 173.939 121.68Z"
          fill="black"
        />
        <path
          d="M165.546 109.656C165.546 109.487 165.594 109.321 165.687 109.173C166.225 108.344 168.405 108.456 169.112 108.555C169.182 108.562 169.25 108.582 169.311 108.613C169.371 108.644 169.424 108.686 169.465 108.736C169.507 108.786 169.536 108.843 169.55 108.904C169.565 108.964 169.565 109.026 169.551 109.087C169.527 109.207 169.45 109.315 169.336 109.386C169.222 109.458 169.081 109.488 168.943 109.47C168.162 109.322 167.348 109.383 166.607 109.643C166.494 109.816 167.287 110.744 167.669 111.177C168.192 111.786 168.665 112.427 169.084 113.095C170.919 115.912 171.821 119.121 171.688 122.361C171.687 122.422 171.671 122.482 171.642 122.538C171.613 122.594 171.572 122.644 171.52 122.685C171.469 122.727 171.408 122.759 171.342 122.78C171.276 122.8 171.206 122.809 171.136 122.806C170.996 122.8 170.864 122.745 170.769 122.655C170.674 122.565 170.623 122.446 170.627 122.323C170.753 119.246 169.89 116.199 168.136 113.528C167.717 112.899 167.244 112.299 166.72 111.734C166.119 111.148 165.713 110.429 165.546 109.656Z"
          fill="black"
        />
        <path
          d="M146.819 113.713C147.088 110.619 147.753 107.561 148.8 104.596C149.904 101.183 151.27 97.8395 152.891 94.588C152.933 94.4971 153.009 94.4212 153.107 94.372C153.204 94.3227 153.317 94.303 153.429 94.3158C153.542 94.3249 153.649 94.366 153.733 94.433C153.818 94.5 153.875 94.5891 153.896 94.687C153.896 94.687 154.419 96.9756 154.83 100.056C154.828 100.171 154.778 100.281 154.689 100.365C154.6 100.45 154.48 100.503 154.349 100.515C154.219 100.528 154.088 100.498 153.981 100.431C153.874 100.365 153.798 100.267 153.768 100.155C153.57 98.6456 153.344 97.322 153.174 96.3818C151.871 99.1341 150.756 101.951 149.833 104.819C149.052 107.177 148.452 109.578 148.036 112.006C148.815 110.481 150.046 109.165 151.603 108.196C152.4 107.79 153.313 107.588 154.235 107.615V106.464C154.235 106.343 154.291 106.226 154.389 106.14C154.487 106.055 154.62 106.006 154.759 106.006C154.829 106.005 154.899 106.015 154.964 106.038C155.029 106.06 155.088 106.093 155.139 106.136C155.189 106.179 155.229 106.23 155.256 106.286C155.283 106.342 155.297 106.403 155.297 106.464V108.097C155.293 108.161 155.275 108.223 155.243 108.28C155.211 108.337 155.167 108.389 155.113 108.431C155.06 108.474 154.998 108.508 154.93 108.529C154.861 108.551 154.789 108.559 154.717 108.555C153.851 108.479 152.978 108.616 152.197 108.951C150.356 110.205 149.018 111.938 148.376 113.899C148.092 114.579 147.908 115.037 147.342 114.913C147.185 114.868 147.05 114.776 146.96 114.654C146.836 114.353 146.788 114.032 146.819 113.713Z"
          fill="black"
        />
        <path
          d="M86.9762 332.543C86.9762 332.42 87.0309 332.302 87.1287 332.214C87.2265 332.127 87.3597 332.076 87.4999 332.072C127.301 332.072 148.801 327.767 149.905 327.025C152.21 325.427 153.936 323.278 154.873 320.84C154.664 316.254 154.749 311.661 155.128 307.083C155.457 302.239 156.1 297.415 157.053 292.634C159.6 284.729 159.756 278.655 159.954 271.603C159.954 270.094 160.039 268.535 160.11 266.915C160.404 259.967 160.215 253.01 159.544 246.082C159.346 243.608 159.133 241.134 159.006 238.35C158.584 229.763 158.665 221.162 159.247 212.582C159.629 205.036 160.11 195.646 160.11 180.17C160.11 159.981 159.728 138.517 159.515 126.975C159.431 121.68 159.36 118.192 159.388 117.412C159.388 117.351 159.402 117.291 159.429 117.234C159.456 117.178 159.496 117.127 159.546 117.084C159.597 117.042 159.656 117.008 159.721 116.986C159.786 116.964 159.856 116.953 159.926 116.955C160.064 116.958 160.195 117.007 160.292 117.092C160.39 117.177 160.446 117.292 160.45 117.412C160.45 118.167 160.45 121.668 160.577 126.938C160.789 138.48 161.171 159.944 161.171 180.133C161.171 195.621 160.69 205.036 160.308 212.594C159.733 221.146 159.653 229.717 160.067 238.276C160.195 241.035 160.407 243.546 160.605 245.983C161.284 252.944 161.473 259.934 161.171 266.915C161.101 268.535 161.058 270.082 161.016 271.579C160.818 278.68 160.648 284.803 158.072 292.82C157.091 297.556 156.439 302.34 156.118 307.145C155.748 311.702 155.667 316.274 155.878 320.84V320.976C154.917 323.628 153.056 325.962 150.542 327.668C148.843 328.905 125.871 332.938 87.4574 332.938C87.3383 332.929 87.2262 332.884 87.1391 332.813C87.0519 332.741 86.9946 332.646 86.9762 332.543Z"
          fill="black"
        />
        <path
          d="M136.585 173.972C136.585 168.158 136.783 163.086 137.35 156.777C137.916 150.467 138.567 146.1 138.765 144.492C140.503 134.232 143.226 124.119 146.904 114.258C146.946 114.14 147.039 114.042 147.164 113.984C147.288 113.926 147.434 113.914 147.569 113.948C147.704 113.988 147.817 114.072 147.883 114.183C147.949 114.294 147.963 114.423 147.923 114.542C144.254 124.348 141.545 134.409 139.827 144.616C139.572 146.212 138.907 150.603 138.411 156.851C137.392 168.084 137.576 175.407 137.93 189.992L138.171 200.792C138.51 216.281 138.638 222.083 140.251 231.719C141.667 240.379 143.761 249.224 151.645 253.814C154.642 255.358 157.981 256.323 161.44 256.647C161.579 256.668 161.703 256.735 161.787 256.834C161.872 256.933 161.909 257.056 161.893 257.179C161.868 257.299 161.791 257.407 161.677 257.479C161.564 257.55 161.423 257.58 161.284 257.562C157.67 257.222 154.18 256.21 151.051 254.593C142.813 249.781 140.676 240.725 139.204 231.856C137.576 222.144 137.449 216.33 137.109 200.792L136.868 190.005C136.713 183.572 136.585 178.549 136.585 173.972Z"
          fill="black"
        />
        <path
          d="M152.113 254.878C152.878 241.616 153.529 227.476 153.953 211.654C154.576 190.797 154.633 173.589 154.69 136.674C154.69 129.919 154.548 123.474 154.406 117.796C154.322 113.948 154.237 110.621 154.237 108.048C154.237 107.926 154.292 107.81 154.39 107.724C154.488 107.638 154.621 107.59 154.76 107.59C154.901 107.59 155.035 107.638 155.136 107.723C155.236 107.809 155.294 107.925 155.298 108.048C155.298 110.608 155.383 113.924 155.468 117.771C155.61 123.462 155.765 129.919 155.751 136.674C155.751 173.601 155.638 190.809 155.015 211.679C154.548 227.501 153.939 241.653 153.175 254.977C153.171 255.039 153.154 255.099 153.123 255.155C153.093 255.211 153.05 255.261 152.998 255.302C152.945 255.344 152.884 255.375 152.817 255.396C152.75 255.417 152.68 255.426 152.609 255.422C152.533 255.42 152.459 255.403 152.392 255.373C152.324 255.344 152.265 255.301 152.218 255.25C152.171 255.198 152.137 255.138 152.119 255.074C152.101 255.01 152.099 254.943 152.113 254.878Z"
          fill="black"
        />
        <path
          d="M136.672 181.086C136.671 180.982 136.709 180.88 136.781 180.798C136.852 180.715 136.953 180.655 137.068 180.628C139.687 180.096 152.638 178.512 155.058 178.438C155.127 178.437 155.195 178.447 155.259 178.468C155.324 178.49 155.382 178.522 155.432 178.563C155.482 178.605 155.522 178.654 155.55 178.709C155.578 178.764 155.594 178.823 155.596 178.884C155.596 179.005 155.544 179.123 155.449 179.212C155.355 179.302 155.225 179.357 155.086 179.366C152.694 179.44 139.899 180.999 137.323 181.531C137.255 181.546 137.184 181.548 137.115 181.539C137.046 181.529 136.98 181.507 136.921 181.475C136.861 181.443 136.81 181.4 136.77 181.351C136.729 181.301 136.701 181.244 136.686 181.185L136.672 181.086Z"
          fill="black"
        />
        <path
          d="M136.585 174.813C136.59 174.725 136.621 174.64 136.676 174.566C136.73 174.492 136.807 174.432 136.897 174.393C139.586 173.329 152.65 169.581 155.028 169.135C155.095 169.121 155.165 169.118 155.233 169.128C155.301 169.138 155.366 169.159 155.424 169.192C155.483 169.224 155.533 169.266 155.572 169.316C155.611 169.366 155.637 169.422 155.651 169.482C155.668 169.541 155.671 169.603 155.66 169.663C155.649 169.723 155.624 169.781 155.587 169.833C155.55 169.885 155.502 169.93 155.444 169.965C155.387 170 155.323 170.025 155.254 170.038C152.905 170.484 139.996 174.195 137.336 175.246C137.272 175.271 137.204 175.285 137.135 175.286C137.066 175.288 136.997 175.277 136.933 175.255C136.868 175.233 136.809 175.201 136.759 175.159C136.71 175.117 136.67 175.067 136.642 175.011C136.602 174.952 136.582 174.883 136.585 174.813Z"
          fill="black"
        />
        <path
          d="M136.614 177.968C136.613 177.877 136.642 177.788 136.697 177.711C136.752 177.634 136.832 177.573 136.926 177.535C139.134 176.681 155.652 173.49 160.648 173.49C160.718 173.49 160.787 173.502 160.852 173.526C160.916 173.55 160.975 173.584 161.023 173.628C161.072 173.672 161.11 173.724 161.136 173.781C161.161 173.838 161.174 173.899 161.172 173.96C161.174 174.02 161.161 174.081 161.136 174.137C161.11 174.193 161.072 174.244 161.023 174.287C160.974 174.33 160.915 174.364 160.851 174.386C160.786 174.409 160.717 174.419 160.648 174.418C155.892 174.418 139.417 177.56 137.365 178.389C137.235 178.436 137.089 178.438 136.957 178.394C136.826 178.35 136.718 178.264 136.657 178.154C136.629 178.095 136.615 178.032 136.614 177.968Z"
          fill="black"
        />
        <path
          d="M157.464 159.659C157.464 155.75 158.879 154.797 159.941 154.711C160.08 154.699 160.22 154.733 160.332 154.806C160.444 154.879 160.522 154.986 160.549 155.107C160.561 155.165 160.56 155.225 160.545 155.283C160.53 155.341 160.502 155.396 160.462 155.445C160.423 155.493 160.373 155.534 160.314 155.565C160.256 155.596 160.192 155.617 160.125 155.626C159.148 155.713 158.582 157.234 158.582 159.671C158.582 171.139 159.544 171.139 160.662 171.139C160.788 171.157 160.903 171.214 160.985 171.299C161.068 171.384 161.113 171.492 161.113 171.603C161.113 171.715 161.068 171.823 160.985 171.908C160.903 171.992 160.788 172.049 160.662 172.067C158.469 172.055 157.464 171.56 157.464 159.659Z"
          fill="black"
        />
        <path
          d="M156.444 231.546C156.444 227.637 157.859 226.685 158.921 226.598C159.06 226.586 159.2 226.62 159.312 226.694C159.425 226.767 159.502 226.874 159.53 226.994C159.542 227.051 159.541 227.111 159.527 227.168C159.513 227.225 159.486 227.28 159.448 227.328C159.41 227.376 159.361 227.418 159.305 227.449C159.248 227.481 159.185 227.503 159.119 227.513H159.048C158.086 227.612 157.506 229.134 157.506 231.571C157.506 243.039 158.468 243.039 159.586 243.039C159.712 243.057 159.827 243.113 159.909 243.198C159.992 243.283 160.037 243.391 160.037 243.503C160.037 243.614 159.992 243.722 159.909 243.807C159.827 243.892 159.712 243.949 159.586 243.967C157.407 243.942 156.444 243.435 156.444 231.546Z"
          fill="black"
        />
        <path
          d="M130.825 207.51C130.825 193.382 131.207 178.685 132.736 162.9C133.812 151.704 134.364 146.088 135.567 141.078C137.11 134.558 139.085 128.123 141.483 121.804C144.979 112.192 147.994 103.903 147.47 92.1137C147.504 88.9525 146.915 85.8098 145.729 82.8232C145.698 82.7681 145.681 82.7082 145.677 82.647C145.673 82.5857 145.683 82.5245 145.707 82.4668C145.731 82.4091 145.767 82.3562 145.815 82.3111C145.863 82.2661 145.92 82.2299 145.984 82.2047C146.112 82.1536 146.258 82.1483 146.39 82.1899C146.522 82.2315 146.631 82.3167 146.692 82.4273C148.008 84.9015 148.447 89.0581 148.588 92.1261C149.126 104.089 146.083 112.439 142.545 122.138C140.132 128.406 138.142 134.792 136.586 141.263C135.439 146.212 134.902 151.816 133.755 162.974C131.42 187.098 131.745 208.747 132.057 229.579C132.17 236.779 132.283 244.214 132.283 251.637C132.283 251.698 132.269 251.758 132.242 251.815C132.215 251.871 132.175 251.922 132.125 251.965C132.075 252.007 132.015 252.041 131.95 252.063C131.885 252.085 131.815 252.096 131.745 252.094C131.676 252.096 131.607 252.085 131.542 252.063C131.478 252.041 131.42 252.007 131.371 251.964C131.321 251.921 131.283 251.87 131.257 251.814C131.232 251.757 131.22 251.697 131.221 251.637C131.221 244.214 131.108 236.791 130.995 229.592C130.882 222.392 130.825 215.006 130.825 207.51Z"
          fill="black"
        />
        <path
          d="M144.541 320.493C144.543 320.4 144.577 320.31 144.637 320.233C144.697 320.156 144.782 320.096 144.88 320.06C146.325 319.434 147.606 318.553 148.644 317.474C149.681 316.395 150.453 315.14 150.91 313.788C151.827 310.749 152.461 307.651 152.807 304.522L153.401 301.182C154.025 298.113 155.308 295.175 157.18 292.523C157.215 292.47 157.261 292.424 157.318 292.388C157.374 292.352 157.438 292.326 157.506 292.313C157.574 292.301 157.645 292.3 157.713 292.313C157.781 292.326 157.846 292.351 157.902 292.386C157.962 292.417 158.014 292.458 158.056 292.506C158.097 292.555 158.127 292.611 158.143 292.67C158.159 292.729 158.161 292.79 158.148 292.85C158.136 292.91 158.11 292.967 158.072 293.017C156.298 295.56 155.07 298.365 154.448 301.294L153.911 304.658C153.54 307.847 152.873 311.004 151.915 314.097C150.99 316.669 149.081 318.883 146.508 320.369C148.164 320.691 151.051 321.013 152.92 319.85C153.57 319.383 154.078 318.783 154.399 318.103C154.72 317.423 154.844 316.685 154.76 315.953C154.747 315.886 154.751 315.817 154.772 315.751C154.793 315.686 154.83 315.625 154.881 315.573C154.931 315.521 154.994 315.479 155.065 315.45C155.136 315.422 155.213 315.407 155.291 315.407C155.369 315.407 155.446 315.422 155.516 315.45C155.587 315.479 155.65 315.521 155.7 315.573C155.751 315.625 155.788 315.686 155.809 315.751C155.83 315.817 155.834 315.886 155.821 315.953C155.912 316.833 155.751 317.718 155.353 318.53C154.956 319.342 154.333 320.055 153.543 320.605C150.372 322.571 145.05 321.013 144.894 320.951C144.791 320.92 144.702 320.862 144.638 320.784C144.575 320.706 144.541 320.613 144.541 320.518V320.493Z"
          fill="black"
        />
        <path
          d="M86.977 330.501C86.977 330.378 87.0317 330.261 87.1295 330.173C87.2274 330.085 87.3606 330.034 87.5007 330.031C128.009 329.759 134.506 326.728 134.775 324.946C134.874 324.266 135.483 321.792 135.511 321.693C135.531 321.611 135.575 321.536 135.64 321.475C135.705 321.414 135.787 321.37 135.879 321.347C136.134 321.272 142.163 319.627 145.15 320.035C145.219 320.044 145.285 320.065 145.344 320.096C145.404 320.127 145.456 320.169 145.497 320.217C145.539 320.266 145.569 320.322 145.586 320.381C145.603 320.44 145.606 320.501 145.596 320.561C145.586 320.621 145.562 320.679 145.526 320.731C145.49 320.783 145.443 320.829 145.387 320.865C145.332 320.901 145.268 320.927 145.201 320.942C145.133 320.957 145.063 320.96 144.994 320.951C142.602 320.629 137.733 321.829 136.502 322.188C136.332 322.856 135.907 324.588 135.837 325.107C135.483 327.495 130.076 330.711 87.529 330.996C87.4591 330.998 87.3895 330.987 87.3243 330.965C87.2591 330.942 87.1997 330.909 87.1496 330.866C87.0994 330.824 87.0596 330.773 87.0324 330.716C87.0052 330.66 86.9912 330.599 86.9912 330.538L86.977 330.501Z"
          fill="black"
        />
        <path
          d="M86.9766 253.245C86.9766 253.123 87.0318 253.007 87.13 252.921C87.2282 252.835 87.3614 252.787 87.5003 252.787C95.1718 252.787 104.485 252.168 112.624 251.624C123.947 250.869 130.33 250.474 132.029 251.216C138.837 254.197 140.097 260.939 143.833 280.906C144.484 284.456 145.249 288.415 146.084 292.856L146.282 293.858C147.81 301.689 149.113 308.456 145.178 315.136C143.31 318.291 138.271 321.322 136.275 322.163C136.212 322.202 136.14 322.229 136.064 322.242C135.988 322.255 135.909 322.253 135.834 322.237C135.759 322.221 135.689 322.191 135.628 322.149C135.567 322.107 135.518 322.054 135.482 321.993C135.447 321.933 135.428 321.867 135.425 321.799C135.422 321.731 135.435 321.664 135.465 321.601C135.494 321.539 135.539 321.482 135.596 321.436C135.653 321.39 135.72 321.355 135.794 321.334C137.917 320.406 142.573 317.499 144.286 314.703C148.079 308.308 146.791 301.677 145.291 294.007L145.093 293.017C144.244 288.576 143.494 284.605 142.842 281.054C139.177 261.397 137.931 254.779 131.604 252.02C130.09 251.352 121.696 251.909 112.765 252.502C104.556 253.059 95.2567 253.678 87.5569 253.678C87.4862 253.683 87.4151 253.676 87.3476 253.656C87.2801 253.637 87.2176 253.607 87.1637 253.567C87.1098 253.526 87.0655 253.477 87.0334 253.422C87.0013 253.367 86.982 253.306 86.9766 253.245Z"
          fill="black"
        />
        <path
          d="M86.9759 301.182C86.9758 301.059 87.0306 300.942 87.1284 300.854C87.2262 300.766 87.3594 300.715 87.4996 300.712C89.2688 300.712 91.1372 300.712 93.1612 300.712C109.849 300.848 132.693 301.021 145.177 292.052C145.229 292.003 145.293 291.964 145.363 291.937C145.434 291.911 145.51 291.898 145.587 291.9C145.664 291.902 145.74 291.918 145.809 291.947C145.878 291.977 145.939 292.019 145.988 292.07C146.037 292.122 146.073 292.182 146.093 292.247C146.113 292.312 146.117 292.38 146.104 292.446C146.092 292.512 146.063 292.576 146.021 292.631C145.978 292.687 145.922 292.734 145.857 292.77C133.118 301.998 109.99 301.813 93.147 301.677C91.2079 301.677 89.3396 301.677 87.5704 301.677C87.4922 301.686 87.4125 301.68 87.3373 301.659C87.2622 301.638 87.1934 301.603 87.1363 301.555C87.0791 301.507 87.035 301.449 87.0072 301.385C86.9794 301.32 86.9687 301.251 86.9759 301.182Z"
          fill="black"
        />
        <path
          d="M86.9766 326.196C86.9766 326.073 87.0313 325.956 87.1291 325.868C87.2269 325.78 87.3601 325.729 87.5003 325.726C116.176 325.726 132.666 322.732 135.794 321.371C135.916 321.336 136.048 321.34 136.167 321.383C136.286 321.426 136.384 321.505 136.442 321.605C136.5 321.705 136.515 321.82 136.485 321.93C136.454 322.039 136.38 322.135 136.275 322.2C132.949 323.66 116.643 326.654 87.5003 326.654C87.4316 326.654 87.3635 326.642 87.2999 326.619C87.2364 326.596 87.1786 326.562 87.13 326.52C87.0814 326.477 87.0428 326.427 87.0165 326.371C86.9902 326.316 86.9766 326.256 86.9766 326.196Z"
          fill="black"
        />
        <path
          d="M140.209 237.126C140.208 237.025 140.244 236.927 140.313 236.846C140.383 236.766 140.48 236.707 140.591 236.68C142.006 236.321 143.903 235.938 146.054 235.505C148.206 235.072 150.966 234.491 153.528 233.884C153.665 233.854 153.81 233.872 153.931 233.935C154.053 233.997 154.142 234.099 154.179 234.218C154.198 234.277 154.204 234.338 154.196 234.399C154.188 234.459 154.166 234.517 154.131 234.57C154.097 234.623 154.051 234.67 153.996 234.707C153.941 234.744 153.878 234.772 153.811 234.787C151.221 235.394 148.602 235.926 146.295 236.396C143.988 236.866 142.304 237.225 140.888 237.571C140.751 237.602 140.606 237.586 140.482 237.526C140.358 237.466 140.265 237.367 140.223 237.249L140.209 237.126Z"
          fill="black"
        />
        <path
          d="M142.658 238.771C142.658 238.683 142.687 238.597 142.743 238.524C142.819 238.424 142.935 238.353 143.069 238.326C143.069 238.326 149.013 237.225 153.444 236.346C153.58 236.32 153.724 236.342 153.843 236.406C153.961 236.471 154.047 236.574 154.08 236.693C154.097 236.752 154.1 236.814 154.089 236.874C154.078 236.934 154.054 236.992 154.017 237.044C153.98 237.096 153.931 237.141 153.874 237.176C153.817 237.211 153.752 237.236 153.684 237.249C150.032 237.967 145.39 238.833 143.776 239.13C144.371 241.06 147.003 248.569 153.019 251.686C153.137 251.75 153.222 251.852 153.256 251.969C153.29 252.087 153.271 252.212 153.203 252.317C153.129 252.424 153.011 252.5 152.873 252.53C152.735 252.56 152.589 252.542 152.467 252.478C145.192 248.705 142.715 239.278 142.559 238.87L142.658 238.771Z"
          fill="black"
        />
        <path
          d="M84.3018 77.1945C84.3018 77.0731 84.3569 76.9567 84.4552 76.8709C84.5534 76.785 84.6866 76.7368 84.8255 76.7368C110.515 76.7368 138.03 78.7904 141.611 83.3924C143.621 85.9779 143.72 88.7861 143.848 92.3365C143.98 98.9358 142.772 105.503 140.281 111.734L139.035 115.012C131.958 133.569 125.193 151.197 127.712 247.95C127.712 248.073 127.657 248.19 127.56 248.278C127.462 248.366 127.329 248.417 127.188 248.42C127.118 248.422 127.049 248.411 126.984 248.389C126.918 248.367 126.859 248.333 126.809 248.291C126.759 248.248 126.719 248.197 126.692 248.141C126.664 248.084 126.65 248.024 126.651 247.963C124.089 151.061 130.897 133.408 137.974 114.715L139.219 111.45C141.677 105.324 142.866 98.8641 142.729 92.3736C142.602 88.8356 142.517 86.2748 140.677 83.9119C137.846 80.2873 114.351 77.6646 84.7688 77.6646C84.6388 77.6496 84.5195 77.5934 84.4335 77.5068C84.3475 77.4203 84.3006 77.3092 84.3018 77.1945Z"
          fill="black"
        />
        <path
          d="M86.9756 70.1057C86.9793 69.9832 87.0376 69.8667 87.1381 69.7812C87.2385 69.6957 87.3733 69.6479 87.5135 69.6479C101.314 69.7964 146.04 77.3798 148.107 87.4373C148.132 87.5586 148.101 87.6836 148.022 87.7854C147.942 87.8872 147.82 87.9576 147.682 87.9816C147.543 88.0035 147.4 87.9768 147.284 87.9074C147.167 87.838 147.087 87.7314 147.059 87.6105C145.219 78.6292 102.899 70.7366 87.4993 70.5758C87.4293 70.5758 87.3601 70.5636 87.2956 70.5398C87.2311 70.516 87.1727 70.4812 87.1239 70.4374C87.0751 70.3935 87.0368 70.3416 87.0114 70.2846C86.9859 70.2277 86.9737 70.1668 86.9756 70.1057Z"
          fill="black"
        />
        <path
          d="M86.9769 136.649C86.9769 136.528 87.0321 136.411 87.1303 136.325C87.2285 136.24 87.3617 136.191 87.5006 136.191C108.732 136.191 126.679 137.948 134.209 140.781C134.273 140.804 134.332 140.838 134.382 140.881C134.431 140.924 134.47 140.976 134.496 141.032C134.523 141.088 134.536 141.149 134.535 141.21C134.534 141.271 134.52 141.331 134.492 141.387C134.433 141.5 134.327 141.589 134.195 141.635C134.063 141.681 133.916 141.681 133.784 141.635C126.495 138.888 108.307 137.119 87.5006 137.119C87.3604 137.116 87.2272 137.065 87.1294 136.977C87.0316 136.889 86.9769 136.772 86.9769 136.649Z"
          fill="black"
        />
        <path
          d="M28.2516 82.6253C28.2588 82.5328 28.2929 82.4433 28.3507 82.3655C36.9886 70.8339 43.2185 58.0549 46.7509 44.6219C50.431 30.6923 49.6808 14.5235 49.3694 9.89677C49.3636 9.83627 49.372 9.77537 49.3941 9.71781C49.4162 9.66025 49.4516 9.60725 49.498 9.56207C49.5443 9.51688 49.6008 9.48047 49.6639 9.45506C49.727 9.42965 49.7954 9.41578 49.8648 9.4143C50.0052 9.40747 50.1431 9.44895 50.2489 9.52988C50.3547 9.61081 50.4201 9.72475 50.431 9.84728C50.7423 14.4987 51.5067 30.7789 47.7842 44.8322C44.2241 58.3722 37.9516 71.255 29.2566 82.8851C29.2181 82.936 29.1684 82.9798 29.1103 83.0136C29.0522 83.0475 28.987 83.0709 28.9184 83.0825C28.8498 83.094 28.7793 83.0934 28.711 83.0808C28.6427 83.0681 28.5779 83.0436 28.5206 83.0088C28.4418 82.9705 28.3756 82.9151 28.3285 82.8479C28.2814 82.7807 28.2549 82.7041 28.2516 82.6253Z"
          fill="black"
        />
        <path
          d="M20.0843 41.9252C20.0843 37.4964 20.7354 30.643 24.3305 26.6966C31.8321 18.5319 43.7923 12.4577 43.9197 12.3959C44.0025 12.351 44.0981 12.3271 44.1956 12.3271C44.2932 12.3271 44.3888 12.351 44.4717 12.3959C44.5535 12.4469 44.6179 12.5165 44.6578 12.5972C44.6977 12.6779 44.7116 12.7665 44.6981 12.8536C44.6981 13.1629 43.4101 20.4493 38.6544 24.7173C37.0963 26.2095 35.2641 27.4638 33.2334 28.4286C32.0341 28.9948 30.9133 29.6797 29.893 30.4698C22.816 35.9625 25.2505 49.6694 25.2647 49.8055C25.2848 49.9019 25.268 50.0015 25.2168 50.0887C25.1656 50.176 25.083 50.246 24.9816 50.288C24.8839 50.3358 24.7705 50.3532 24.66 50.3375C24.5495 50.3218 24.4483 50.2738 24.373 50.2014L21.9385 48.0736C20.8527 47.1319 20.2069 45.8706 20.1268 44.5355C20.1126 43.8675 20.0843 42.952 20.0843 41.9252ZM43.4667 13.7319C40.4519 15.3649 31.3084 20.6225 25.1798 27.3399C20.9336 31.9419 20.9335 40.9479 21.2166 44.5602C21.2794 45.6672 21.8147 46.7133 22.7169 47.4921L24.0474 48.7292C23.5945 45.018 23 34.7377 29.2136 29.9131C30.29 29.0855 31.4672 28.3636 32.7238 27.7605C34.6819 26.798 36.4385 25.5513 37.9183 24.074C41.4285 20.9318 42.9572 15.8721 43.4667 13.7319Z"
          fill="black"
        />
        <path
          d="M43.6794 12.8039C43.6777 12.7316 43.6961 12.66 43.7331 12.5954C43.7701 12.5307 43.8245 12.4749 43.8917 12.4327C48.2936 9.52558 64.6981 2.01644 87.5002 2.01644C87.5772 2.00542 87.6559 2.00916 87.7311 2.0274C87.8062 2.04564 87.8759 2.07795 87.9354 2.12208C87.9948 2.16621 88.0426 2.22111 88.0753 2.28298C88.108 2.34484 88.125 2.41218 88.125 2.48035C88.125 2.54851 88.108 2.61587 88.0753 2.67773C88.0426 2.7396 87.9948 2.79449 87.9354 2.83862C87.8759 2.88276 87.8062 2.91506 87.7311 2.9333C87.6559 2.95154 87.5772 2.95528 87.5002 2.94426C60.8058 2.94426 44.6843 13.0637 44.5287 13.1626C44.4735 13.2013 44.41 13.2298 44.3419 13.2464C44.2738 13.263 44.2025 13.2674 44.1324 13.2594C44.0623 13.2513 43.9948 13.2308 43.934 13.1993C43.8732 13.1678 43.8203 13.1259 43.7785 13.076C43.714 12.9965 43.6793 12.9014 43.6794 12.8039Z"
          fill="black"
        />
        <path
          d="M24.7278 18.5438V17.8016C24.7386 17.6791 24.804 17.5651 24.9098 17.4842C25.0157 17.4033 25.1535 17.3618 25.2939 17.3686C25.4328 17.3811 25.5611 17.439 25.653 17.5308C25.7449 17.6227 25.7936 17.7415 25.7893 17.8634C25.6761 19.1005 25.8884 19.6077 25.9733 19.6696C26.7943 20.1521 29.1721 18.6923 33.0503 16.28L33.9137 15.748C38.9853 12.6986 44.2443 9.89441 49.6671 7.34817C49.6671 7.06364 49.5963 6.63066 49.5256 6.23479C49.5135 6.17459 49.5153 6.11288 49.5309 6.0533C49.5465 5.99373 49.5757 5.93749 49.6166 5.88791C49.6575 5.83833 49.7093 5.79641 49.769 5.76462C49.8288 5.73284 49.8952 5.71183 49.9643 5.70284C50.1024 5.68797 50.2418 5.71921 50.3547 5.79032C50.4675 5.86142 50.5455 5.96715 50.573 6.08634C50.7004 6.76674 50.757 7.59559 50.757 7.59559C50.7627 7.68395 50.7391 7.77188 50.6888 7.84873C50.6386 7.92558 50.5639 7.98805 50.4739 8.02857C44.9775 10.5917 39.6518 13.425 34.5223 16.515L33.659 17.0346C28.7758 20.0407 26.7518 21.2778 25.3081 20.4366C25.0958 20.2634 24.7278 19.8675 24.7278 18.5438Z"
          fill="black"
        />
        <path
          d="M12.7954 49.1742C12.7954 44.8073 12.8945 40.9846 13.1776 38.127C13.405 34.4911 14.269 30.9073 15.7394 27.5004C22.7032 12.8532 56.234 -0.00016194 87.5002 -0.00016194C87.5772 -0.0111832 87.6559 -0.00744467 87.7311 0.0107959C87.8062 0.0290364 87.8759 0.06134 87.9354 0.105471C87.9948 0.149602 88.0426 0.2045 88.0753 0.266365C88.108 0.328229 88.125 0.395583 88.125 0.463747C88.125 0.531911 88.108 0.599255 88.0753 0.66112C88.0426 0.722984 87.9948 0.777892 87.9354 0.822023C87.8759 0.866154 87.8062 0.898458 87.7311 0.916698C87.6559 0.934939 87.5772 0.938677 87.5002 0.927656C57.154 0.927656 23.4109 13.7563 16.7302 27.8591C15.3021 31.1798 14.4621 34.6714 14.2391 38.2136C13.3757 47.3062 14.2391 66.4069 14.8477 81.7591C15.2157 90.4188 15.5271 97.8413 15.5271 102.567C15.529 102.628 15.5169 102.689 15.4914 102.746C15.4659 102.803 15.4277 102.855 15.3788 102.899C15.33 102.943 15.2716 102.977 15.2072 103.001C15.1427 103.025 15.0734 103.037 15.0034 103.037C14.8608 103.037 14.724 102.988 14.6231 102.899C14.5222 102.811 14.4656 102.692 14.4656 102.567C14.4656 97.8908 14.1542 90.4312 13.7862 81.7963C13.3191 71.2068 12.7954 58.8359 12.7954 49.1742Z"
          fill="black"
        />
        <path
          d="M-0.0563183 121.68C-0.0563183 117.969 0.339991 114.889 5.49204 108.74C10.2902 103.025 20.75 99.5859 21.1888 99.4374C21.2731 99.4108 21.3636 99.4025 21.4524 99.4133C21.5411 99.4241 21.6256 99.4536 21.6984 99.4993C21.7697 99.5437 21.8267 99.6035 21.8639 99.6728C21.9011 99.7422 21.9172 99.8187 21.9107 99.8951L21.5851 105.747C21.5851 105.814 21.5672 105.88 21.5328 105.94C21.4984 106 21.4485 106.053 21.387 106.093C19.9716 107.083 17.7069 109.074 17.7069 110.2C17.7014 111.096 17.7866 111.99 17.9617 112.872C18.2213 114.005 18.1679 115.175 17.806 116.287C17.7654 116.393 17.6858 116.484 17.5796 116.546C15.3008 117.87 3.77942 124.414 0.764616 123.004C0.495148 122.877 0.273916 122.684 0.128078 122.449C-0.0177593 122.214 -0.081852 121.947 -0.0563183 121.68ZM20.8067 100.576C18.3156 101.479 10.2336 104.658 6.35544 109.235C1.00523 115.619 1.00523 118.674 1.00523 121.606C1.00523 121.99 1.18922 122.076 1.25999 122.113C3.14247 122.992 11.7339 118.748 16.8294 115.804C17.1102 114.866 17.1344 113.882 16.9002 112.934C16.7303 112.005 16.6451 111.066 16.6454 110.126C16.6454 108.357 19.6744 106.043 20.5377 105.425L20.8067 100.576Z"
          fill="black"
        />
        <path
          d="M3.27033 121.594C3.31496 118.612 4.22051 115.689 5.90297 113.095C6.32223 112.427 6.79522 111.786 7.31837 111.177C7.70053 110.744 8.49314 109.816 8.36575 109.631C7.62635 109.381 6.81968 109.325 6.0445 109.47C5.90588 109.488 5.76471 109.458 5.65104 109.386C5.53737 109.315 5.46019 109.207 5.43589 109.087C5.42168 109.026 5.42197 108.964 5.43673 108.904C5.45149 108.843 5.48041 108.786 5.52165 108.736C5.56289 108.686 5.61557 108.644 5.67633 108.613C5.73709 108.582 5.80463 108.562 5.87466 108.555C6.58236 108.456 8.70545 108.344 9.29992 109.173C9.89439 110.002 9.0593 110.707 8.1676 111.734C7.6534 112.305 7.18067 112.905 6.7522 113.528C5.00758 116.202 4.14986 119.247 4.27525 122.323C4.28786 122.391 4.28359 122.46 4.26272 122.525C4.24185 122.591 4.20488 122.652 4.15439 122.704C4.1039 122.756 4.04108 122.797 3.9703 122.826C3.89951 122.855 3.82246 122.87 3.74448 122.87C3.66649 122.87 3.58944 122.855 3.51865 122.826C3.44787 122.797 3.38505 122.756 3.33456 122.704C3.28407 122.652 3.24712 122.591 3.22625 122.525C3.20538 122.46 3.20109 122.391 3.2137 122.323L3.27033 121.594Z"
          fill="black"
        />
        <path
          d="M19.7596 106.823V106.402C19.7595 106.341 19.7735 106.28 19.8007 106.224C19.8279 106.168 19.8678 106.117 19.9179 106.074C19.968 106.031 20.0275 105.998 20.0927 105.976C20.1578 105.953 20.2275 105.943 20.2974 105.944C20.4352 105.947 20.5663 105.997 20.6637 106.082C20.7612 106.167 20.8175 106.282 20.8211 106.402C20.8353 106.542 20.8353 106.683 20.8211 106.823V107.553C21.7483 107.525 22.6656 107.727 23.4679 108.134C25.0385 109.124 26.2675 110.472 27.0206 112.031C26.6182 109.602 26.0223 107.2 25.2371 104.843C24.315 101.976 23.1996 99.1587 21.8968 96.4064C21.727 97.3466 21.5005 98.6703 21.2882 100.18C21.2831 100.24 21.2637 100.3 21.2312 100.354C21.1988 100.408 21.1539 100.456 21.0995 100.494C21.0451 100.532 20.9822 100.56 20.9149 100.576C20.8475 100.593 20.777 100.596 20.7079 100.588C20.6389 100.582 20.5721 100.564 20.5112 100.535C20.4503 100.506 20.3966 100.467 20.3532 100.419C20.3097 100.372 20.2774 100.318 20.2581 100.26C20.2388 100.202 20.2329 100.141 20.2408 100.081C20.6513 97.0002 21.1608 94.7363 21.175 94.7116C21.1923 94.6121 21.2467 94.5204 21.3297 94.4506C21.4126 94.3808 21.5196 94.3369 21.6341 94.3256C21.7485 94.3144 21.864 94.3364 21.9626 94.3882C22.0613 94.4401 22.1377 94.519 22.1799 94.6126C23.8005 97.8542 25.1625 101.19 26.2562 104.596C27.9689 109.915 28.5492 113.924 28.0963 114.592C28.0122 114.713 27.882 114.805 27.7283 114.851C27.1621 114.975 26.9781 114.517 26.695 113.837C26.0472 111.878 24.7102 110.146 22.8734 108.889C22.0939 108.55 21.2198 108.412 20.354 108.493C20.2818 108.497 20.2092 108.489 20.141 108.467C20.0728 108.446 20.0104 108.413 19.9577 108.369C19.9034 108.327 19.8591 108.275 19.8275 108.218C19.796 108.161 19.7777 108.099 19.7737 108.035L19.7596 106.823Z"
          fill="black"
        />
        <path
          d="M13.6446 260.358C13.6446 254.556 13.9985 250.226 14.3523 246.008C14.5505 243.534 14.7628 241.059 14.8902 238.301C15.3046 229.742 15.2243 221.17 14.6496 212.619C14.2674 205.06 13.7862 195.646 13.7862 180.157C13.7862 159.956 14.1683 138.492 14.3665 126.962C14.4656 121.692 14.5363 118.204 14.508 117.437C14.5116 117.316 14.5679 117.202 14.6654 117.117C14.7628 117.032 14.894 116.982 15.0317 116.979C15.1017 116.978 15.1713 116.988 15.2365 117.01C15.3017 117.033 15.3611 117.066 15.4112 117.109C15.4614 117.151 15.5012 117.202 15.5284 117.259C15.5556 117.315 15.5696 117.376 15.5696 117.437C15.5696 118.204 15.5696 121.705 15.428 127C15.2299 138.529 14.8477 159.993 14.8477 180.194C14.8477 195.67 15.329 205.06 15.7111 212.606C16.2857 221.187 16.366 229.787 15.9517 238.375C15.8244 241.146 15.612 243.67 15.4139 246.107C14.7427 253.034 14.5536 259.991 14.8477 266.939C14.9185 268.56 14.961 270.119 15.0034 271.628C15.1874 278.654 15.3573 284.729 17.905 292.658C18.8713 297.451 19.5328 302.288 19.8866 307.145C20.2653 311.722 20.3504 316.315 20.1413 320.901C21.0715 323.342 22.7984 325.493 25.1094 327.087C26.2134 327.829 47.7558 332.134 87.5002 332.134C87.5772 332.123 87.6559 332.127 87.7311 332.145C87.8062 332.163 87.8759 332.195 87.9354 332.24C87.9948 332.284 88.0426 332.339 88.0753 332.4C88.108 332.462 88.125 332.53 88.125 332.598C88.125 332.666 88.108 332.733 88.0753 332.795C88.0426 332.857 87.9948 332.912 87.9354 332.956C87.8759 333 87.8062 333.033 87.7311 333.051C87.6559 333.069 87.5772 333.073 87.5002 333.062C49.1004 333.062 26.1285 329.029 24.43 327.792C21.9101 326.09 20.0477 323.754 19.0939 321.099V320.963C19.2961 316.398 19.211 311.826 18.8392 307.268C18.5327 302.444 17.8946 297.639 16.9284 292.881C14.3523 284.865 14.1825 278.741 13.9843 271.64C13.9843 270.143 13.8994 268.597 13.8286 266.976C13.6871 264.564 13.6446 262.387 13.6446 260.358Z"
          fill="black"
        />
        <path
          d="M13.1203 257.117C13.1221 257.006 13.1685 256.899 13.2513 256.816C13.3342 256.732 13.4482 256.677 13.5733 256.659C17.0318 256.336 20.3713 255.37 23.3678 253.826C31.2516 249.236 33.2756 240.391 34.691 231.732C36.3046 222.082 36.432 216.28 36.7717 200.804L37.0123 190.005C37.3661 175.419 37.536 168.096 36.531 156.863C35.979 150.616 35.3138 146.224 35.1156 144.628C33.39 134.422 30.6817 124.362 27.0196 114.554C26.9785 114.436 26.9908 114.309 27.054 114.198C27.1172 114.088 27.2264 114.003 27.3592 113.961C27.4944 113.926 27.6398 113.939 27.7642 113.996C27.8886 114.054 27.982 114.152 28.0245 114.27C31.7156 124.128 34.4383 134.242 36.163 144.504C36.432 146.113 37.083 150.517 37.6492 156.789C38.6541 168.071 38.4843 175.345 38.1304 190.029L37.8898 200.817C37.5501 216.355 37.4227 222.169 35.795 231.88C34.3796 240.75 32.1858 249.806 23.9481 254.618C20.8185 256.234 17.3292 257.247 13.7148 257.587C13.5762 257.605 13.435 257.575 13.3214 257.503C13.2077 257.432 13.1305 257.324 13.1062 257.203L13.1203 257.117Z"
          fill="black"
        />
        <path
          d="M19.2635 135.919C19.2635 129.424 19.4051 123.239 19.5466 117.746C19.6316 113.899 19.7165 110.583 19.7165 108.023C19.7202 107.9 19.7785 107.784 19.8789 107.698C19.9794 107.613 20.1141 107.565 20.2543 107.565C20.3932 107.565 20.5264 107.613 20.6246 107.699C20.7229 107.785 20.778 107.901 20.778 108.023C20.778 110.596 20.6931 113.924 20.6082 117.771C20.4666 123.449 20.2826 129.907 20.3251 136.649C20.3251 173.564 20.4383 190.772 21.0611 211.629C21.5282 227.451 22.1368 241.591 22.9011 254.853C22.9137 254.92 22.9095 254.989 22.8886 255.055C22.8677 255.12 22.8308 255.181 22.7803 255.233C22.7298 255.285 22.667 255.327 22.5962 255.356C22.5254 255.384 22.4483 255.399 22.3704 255.399C22.2924 255.399 22.2153 255.384 22.1445 255.356C22.0738 255.327 22.0109 255.285 21.9604 255.233C21.9099 255.181 21.873 255.12 21.8521 255.055C21.8312 254.989 21.827 254.92 21.8396 254.853C21.0753 241.579 20.4242 227.427 19.9996 211.555C19.3768 190.685 19.3202 173.477 19.2635 136.55V135.919Z"
          fill="black"
        />
        <path
          d="M19.406 178.908C19.4134 178.785 19.4752 178.67 19.5783 178.587C19.6815 178.504 19.8177 178.459 19.958 178.463C22.3925 178.537 35.3293 180.12 37.9336 180.652C38.0015 180.666 38.0655 180.691 38.1222 180.727C38.1788 180.762 38.2269 180.807 38.2637 180.859C38.3005 180.911 38.3253 180.968 38.3366 181.028C38.348 181.088 38.3457 181.15 38.3299 181.209C38.3141 181.268 38.2851 181.324 38.2446 181.374C38.204 181.423 38.1527 181.465 38.0935 181.497C38.0344 181.53 37.9686 181.551 37.8999 181.561C37.8311 181.571 37.7608 181.569 37.693 181.555C35.117 181.023 22.3218 179.465 19.9297 179.39C19.8591 179.389 19.7895 179.375 19.725 179.35C19.6604 179.325 19.6021 179.289 19.5535 179.244C19.5049 179.199 19.4668 179.146 19.4415 179.089C19.4162 179.031 19.4042 178.97 19.406 178.908Z"
          fill="black"
        />
        <path
          d="M19.3479 169.593V169.482C19.3612 169.422 19.3882 169.366 19.4271 169.316C19.466 169.266 19.5161 169.224 19.5744 169.192C19.6326 169.159 19.6977 169.138 19.7659 169.128C19.834 169.118 19.9037 169.121 19.9707 169.135C22.3486 169.581 35.4127 173.329 38.0878 174.393C38.1981 174.452 38.2802 174.544 38.319 174.652C38.3578 174.76 38.3508 174.877 38.2992 174.981C38.2475 175.084 38.1548 175.168 38.0379 175.217C37.9211 175.266 37.788 175.276 37.6632 175.246C35.0022 174.195 22.0938 170.484 19.7442 170.038C19.6308 170.013 19.5301 169.956 19.4582 169.875C19.3864 169.795 19.3476 169.695 19.3479 169.593Z"
          fill="black"
        />
        <path
          d="M13.8426 173.96C13.8407 173.899 13.8528 173.838 13.8783 173.781C13.9038 173.724 13.942 173.672 13.9909 173.628C14.0397 173.584 14.098 173.55 14.1625 173.526C14.227 173.502 14.2963 173.49 14.3663 173.49C19.3626 173.49 35.8803 176.681 38.0883 177.535C38.2044 177.593 38.2916 177.687 38.333 177.798C38.3743 177.909 38.3669 178.029 38.3121 178.136C38.2574 178.242 38.1592 178.327 38.0367 178.374C37.9142 178.421 37.7762 178.426 37.6496 178.389C35.5406 177.56 19.1078 174.418 14.3663 174.418C14.297 174.419 14.228 174.409 14.1635 174.386C14.0991 174.364 14.0406 174.33 13.9916 174.287C13.9426 174.244 13.9041 174.193 13.8784 174.137C13.8528 174.081 13.8406 174.02 13.8426 173.96Z"
          fill="black"
        />
        <path
          d="M13.8844 171.597C13.8825 171.535 13.8946 171.475 13.9201 171.418C13.9456 171.361 13.9838 171.309 14.0327 171.265C14.0815 171.221 14.1399 171.186 14.2043 171.162C14.2688 171.139 14.3381 171.126 14.4081 171.126C15.5262 171.126 16.4887 171.126 16.4887 159.659C16.4887 157.184 15.9084 155.7 14.9459 155.613V155.143L15.0309 154.686L14.9459 155.143V154.686C16.1066 154.797 17.4512 155.749 17.4512 159.634C17.4512 171.535 16.4887 172.005 14.309 172.005C14.197 171.986 14.095 171.937 14.0184 171.863C13.9418 171.789 13.8947 171.696 13.8844 171.597Z"
          fill="black"
        />
        <path
          d="M14.8908 243.472C14.8945 243.349 14.9527 243.233 15.0532 243.147C15.1537 243.062 15.2884 243.014 15.4286 243.014C16.5468 243.014 17.5093 243.014 17.5093 231.546C17.5093 229.072 16.929 227.588 15.9665 227.489V227.031L16.0655 226.573L15.9665 227.031L16.0514 226.573C17.212 226.685 18.5566 227.637 18.5566 231.522C18.5566 243.41 17.5942 243.893 15.4145 243.893C15.2835 243.89 15.1581 243.846 15.0617 243.769C14.9653 243.691 14.9045 243.586 14.8908 243.472Z"
          fill="black"
        />
        <path
          d="M26.3966 94.8725C26.3966 93.9694 26.3966 93.0539 26.3966 92.1261C26.5381 89.0581 26.9769 84.8891 28.2932 82.4273C28.3545 82.3167 28.4629 82.2315 28.5951 82.1899C28.7274 82.1483 28.8731 82.1536 29.0009 82.2047C29.0649 82.2299 29.1224 82.2661 29.17 82.3111C29.2176 82.3562 29.2544 82.4091 29.2782 82.4668C29.3019 82.5245 29.3122 82.5857 29.3083 82.647C29.3045 82.7082 29.2866 82.7681 29.2557 82.8232C28.0439 85.8224 27.4357 88.9825 27.4582 92.1632C26.9345 103.94 29.9492 112.241 33.4453 121.853C35.8695 128.155 37.8733 134.574 39.4466 141.078C40.5931 146.026 41.1309 151.704 42.2774 162.9C44.6128 187.073 44.2872 208.672 43.9759 229.592C43.8626 236.791 43.7494 244.226 43.7494 251.637C43.7288 251.747 43.6641 251.847 43.5668 251.919C43.4696 251.991 43.3462 252.031 43.2186 252.031C43.091 252.031 42.9676 251.991 42.8704 251.919C42.7732 251.847 42.7085 251.747 42.6878 251.637C42.6878 244.214 42.8011 236.791 42.9143 229.579C43.2257 208.697 43.5512 187.098 41.2158 162.974C40.1401 151.84 39.6023 146.212 38.4558 141.263C36.8997 134.792 34.91 128.406 32.497 122.138C29.2416 113.206 26.3966 105.388 26.3966 94.8725Z"
          fill="black"
        />
        <path
          d="M16.8565 292.757C16.8568 292.681 16.879 292.606 16.921 292.539C16.963 292.472 17.0236 292.415 17.0971 292.374C17.1546 292.338 17.2201 292.313 17.2894 292.3C17.3587 292.287 17.4302 292.287 17.4994 292.3C17.5687 292.313 17.6341 292.338 17.6916 292.374C17.749 292.411 17.7972 292.457 17.8331 292.51C19.7057 295.163 20.9879 298.101 21.6122 301.17L22.1501 304.547C22.5197 307.676 23.1728 310.774 24.1034 313.813C24.5581 315.164 25.3271 316.418 26.3622 317.497C27.3973 318.577 28.6762 319.457 30.1188 320.085C30.219 320.123 30.305 320.185 30.3675 320.264C30.4299 320.342 30.4663 320.435 30.4727 320.53C30.4676 320.626 30.4299 320.719 30.3643 320.796C30.2988 320.873 30.2083 320.931 30.1046 320.963C29.8923 320.963 24.6412 322.584 21.4707 320.617C20.6798 320.067 20.0575 319.354 19.6598 318.542C19.262 317.73 19.1013 316.845 19.1919 315.965C19.1793 315.898 19.1836 315.829 19.2044 315.763C19.2253 315.698 19.2623 315.637 19.3128 315.585C19.3633 315.533 19.4261 315.491 19.4969 315.463C19.5676 315.434 19.6447 315.419 19.7227 315.419C19.8007 315.419 19.8777 315.434 19.9485 315.463C20.0193 315.491 20.0821 315.533 20.1326 315.585C20.1831 315.637 20.2201 315.698 20.2409 315.763C20.2618 315.829 20.2661 315.898 20.2535 315.965C20.1677 316.696 20.2894 317.434 20.6079 318.114C20.9264 318.793 21.4318 319.394 22.0793 319.862C23.9477 321.025 26.8492 320.703 28.5052 320.382C25.9326 318.895 24.0235 316.681 23.0984 314.11C22.1408 311.016 21.4733 307.859 21.1027 304.671L20.5507 301.306C19.9417 298.376 18.718 295.57 16.9414 293.03C16.8801 292.949 16.8503 292.854 16.8565 292.757Z"
          fill="black"
        />
        <path
          d="M29.4113 320.493C29.4124 320.384 29.4568 320.279 29.5368 320.195C29.6168 320.112 29.7276 320.055 29.85 320.035C32.8365 319.627 38.8803 321.272 39.1351 321.347C39.2267 321.37 39.3093 321.414 39.3742 321.475C39.439 321.536 39.4836 321.611 39.5031 321.693C39.5031 321.792 40.14 324.266 40.2391 324.946C40.508 326.728 47.0047 329.759 87.5134 330.031C87.6536 330.034 87.7868 330.085 87.8846 330.173C87.9824 330.261 88.0372 330.378 88.0371 330.501C88.0371 330.562 88.0231 330.623 87.9959 330.679C87.9687 330.735 87.9289 330.786 87.8788 330.829C87.8286 330.872 87.7692 330.905 87.704 330.928C87.6388 330.95 87.5692 330.96 87.4993 330.959C44.9524 330.674 39.5455 327.458 39.1917 325.07C39.1067 324.551 38.6963 322.819 38.5264 322.151C37.2809 321.829 32.4119 320.629 30.034 320.914C29.9652 320.924 29.8946 320.923 29.8264 320.909C29.7582 320.895 29.6939 320.87 29.6372 320.834C29.5804 320.798 29.5325 320.753 29.4961 320.701C29.4597 320.649 29.4357 320.591 29.4254 320.53L29.4113 320.493Z"
          fill="black"
        />
        <path
          d="M27.2174 305.165C27.3649 301.379 27.8715 297.609 28.7319 293.895L28.9159 292.893C29.7793 288.452 30.5294 284.494 31.1805 280.943C34.9172 260.939 36.1769 254.197 42.985 251.253C44.6834 250.511 51.1376 250.907 62.3901 251.661C70.5711 252.205 79.842 252.824 87.4993 252.824C87.5692 252.822 87.6388 252.833 87.704 252.855C87.7692 252.878 87.8286 252.911 87.8788 252.954C87.9289 252.996 87.9687 253.047 87.9959 253.104C88.0231 253.16 88.0371 253.221 88.0371 253.282C88.0371 253.406 87.9804 253.526 87.8796 253.614C87.7787 253.702 87.6419 253.752 87.4993 253.752C79.7995 253.752 70.5145 253.133 62.3051 252.577C53.3881 251.983 44.9807 251.426 43.4662 252.094C37.1252 254.853 35.8938 261.471 32.2279 281.129C31.5768 284.679 30.8125 288.65 29.9633 293.091L29.7793 294.081C28.2789 301.751 26.9485 308.382 30.7842 314.777C32.4402 317.573 37.0969 320.48 39.2766 321.408C39.3812 321.474 39.4556 321.57 39.4861 321.679C39.5167 321.788 39.5016 321.903 39.4434 322.003C39.3852 322.104 39.2878 322.182 39.1689 322.225C39.05 322.268 38.9175 322.272 38.7954 322.237C36.7855 321.359 31.7184 318.365 29.8925 315.21C28.0651 312.107 27.1461 308.655 27.2174 305.165Z"
          fill="black"
        />
        <path
          d="M29.0291 292.374C29.0285 292.265 29.0738 292.159 29.1565 292.077C29.2473 291.983 29.3768 291.924 29.5171 291.912C29.6574 291.901 29.7973 291.938 29.9067 292.015C42.3905 301.021 65.2916 300.848 81.9226 300.675C83.8617 300.675 85.7442 300.675 87.4993 300.675C87.6419 300.675 87.7787 300.724 87.8796 300.813C87.9804 300.901 88.0371 301.02 88.0371 301.145C88.0371 301.206 88.0231 301.267 87.9959 301.323C87.9687 301.379 87.9289 301.43 87.8788 301.473C87.8286 301.516 87.7692 301.549 87.704 301.572C87.6388 301.594 87.5692 301.604 87.4993 301.603C85.7442 301.603 83.8758 301.603 81.9226 301.603C65.0793 301.739 42.0225 301.924 29.2273 292.696C29.1713 292.656 29.125 292.608 29.091 292.552C29.0569 292.497 29.0359 292.437 29.0291 292.374Z"
          fill="black"
        />
        <path
          d="M38.4415 321.792C38.4269 321.723 38.4269 321.651 38.4415 321.582C38.4731 321.527 38.5168 321.477 38.5702 321.437C38.6235 321.396 38.6855 321.365 38.7525 321.346C38.8196 321.326 38.8904 321.319 38.9609 321.323C39.0313 321.327 39.1002 321.344 39.1634 321.371C42.2772 322.732 58.7808 325.726 87.4426 325.726C87.5853 325.726 87.7221 325.776 87.8229 325.864C87.9238 325.952 87.9805 326.071 87.9805 326.196C87.9805 326.257 87.9665 326.318 87.9393 326.374C87.9121 326.431 87.8723 326.482 87.8221 326.524C87.772 326.567 87.7126 326.6 87.6474 326.623C87.5822 326.645 87.5126 326.655 87.4426 326.654C58.3137 326.654 42.0083 323.66 38.6821 322.2C38.6063 322.155 38.5447 322.094 38.5027 322.023C38.4607 321.952 38.4396 321.872 38.4415 321.792Z"
          fill="black"
        />
        <path
          d="M20.8073 234.379V234.255C20.8239 234.197 20.8535 234.142 20.8945 234.093C20.9355 234.044 20.9871 234.003 21.0463 233.972C21.1055 233.941 21.1711 233.921 21.2394 233.912C21.3077 233.903 21.3773 233.906 21.4443 233.921C24.0203 234.527 26.6246 235.059 28.9317 235.542C31.2388 236.024 32.9515 236.358 34.381 236.717C34.4483 236.733 34.5113 236.76 34.5664 236.797C34.6215 236.835 34.6675 236.881 34.7017 236.934C34.736 236.987 34.7578 237.045 34.766 237.106C34.7741 237.166 34.7683 237.228 34.749 237.286C34.731 237.345 34.6998 237.4 34.6572 237.448C34.6146 237.496 34.5614 237.536 34.5007 237.566C34.4401 237.596 34.3733 237.615 34.3041 237.623C34.2349 237.63 34.1649 237.625 34.0979 237.608C32.6825 237.261 30.8284 236.878 28.6911 236.433C26.5538 235.987 23.7655 235.43 21.1753 234.824C21.0683 234.793 20.9752 234.733 20.9091 234.653C20.8431 234.574 20.8075 234.478 20.8073 234.379Z"
          fill="black"
        />
        <path
          d="M20.9198 236.853V236.742C20.9347 236.682 20.9632 236.626 21.0036 236.576C21.0439 236.526 21.0953 236.484 21.1546 236.451C21.214 236.419 21.2801 236.398 21.3492 236.388C21.4182 236.378 21.4888 236.381 21.5567 236.396C25.9869 237.274 31.9174 238.375 31.9316 238.375C32.065 238.402 32.1814 238.473 32.2571 238.573C32.2871 238.627 32.3027 238.686 32.3027 238.746C32.3027 238.806 32.2871 238.865 32.2571 238.919C32.158 239.328 29.6811 248.754 22.3493 252.527C22.2272 252.591 22.0813 252.61 21.9433 252.58C21.8054 252.55 21.6867 252.473 21.6133 252.366C21.5776 252.315 21.554 252.257 21.5439 252.197C21.5338 252.137 21.5375 252.076 21.5546 252.017C21.5717 251.959 21.6019 251.904 21.6436 251.855C21.6853 251.807 21.7375 251.766 21.7973 251.736C27.8127 248.618 30.4454 241.109 31.0399 239.179C29.4688 238.882 24.8263 238.016 21.1321 237.299C21.0527 237.249 20.9908 237.181 20.9533 237.102C20.9158 237.024 20.9042 236.938 20.9198 236.853Z"
          fill="black"
        />
        <path
          d="M28.4643 97.1485V95.6764C28.5917 92.1259 28.6908 89.3177 30.7148 86.7198C34.2958 82.1179 61.8536 80.0643 87.4865 80.0643C87.5635 80.0533 87.6423 80.057 87.7174 80.0753C87.7925 80.0935 87.8623 80.1258 87.9217 80.1699C87.9811 80.2141 88.0289 80.269 88.0616 80.3308C88.0944 80.3927 88.1113 80.46 88.1113 80.5282C88.1113 80.5964 88.0944 80.6637 88.0616 80.7256C88.0289 80.7875 87.9811 80.8423 87.9217 80.8865C87.8623 80.9306 87.7925 80.9629 87.7174 80.9812C87.6423 80.9994 87.5635 81.0031 87.4865 80.9921C57.9188 80.9921 34.409 83.6271 31.5924 87.2394C29.7524 89.6146 29.6533 92.1878 29.5259 95.7011C29.3984 102.192 30.5917 108.651 33.0502 114.777L34.2958 118.055C41.4436 136.735 48.2092 154.401 45.619 251.302C45.6117 251.423 45.5519 251.536 45.4518 251.619C45.3517 251.702 45.219 251.748 45.0811 251.748C45.01 251.752 44.9388 251.742 44.8723 251.719C44.8058 251.697 44.7457 251.662 44.696 251.618C44.6463 251.573 44.6082 251.52 44.5843 251.461C44.5603 251.402 44.5512 251.34 44.5574 251.278C47.1193 154.537 40.3112 136.958 33.2343 118.34L31.9745 115.062C29.7069 109.295 28.5215 103.246 28.4643 97.1485Z"
          fill="black"
        />
        <path
          d="M26.8355 87.5117V87.4251C28.8878 77.3676 73.6286 69.7842 87.4287 69.6357C87.569 69.6357 87.7037 69.6835 87.8042 69.769C87.9046 69.8545 87.9629 69.9709 87.9666 70.0935C87.9685 70.1546 87.9563 70.2155 87.9309 70.2724C87.9054 70.3294 87.8671 70.3813 87.8183 70.4252C87.7695 70.469 87.7111 70.5038 87.6466 70.5276C87.5822 70.5513 87.5129 70.5636 87.4429 70.5636C72.0434 70.7244 29.7229 78.617 27.8829 87.5983C27.8537 87.7093 27.7793 87.8072 27.6738 87.8738C27.5682 87.9405 27.4385 87.9714 27.3088 87.9609C27.1791 87.9503 27.0582 87.8989 26.9685 87.8163C26.8789 87.7337 26.8266 87.6255 26.8214 87.5117H26.8355Z"
          fill="black"
        />
        <path
          d="M40.4812 141.201C40.4836 141.111 40.5156 141.024 40.5734 140.949C40.6311 140.875 40.712 140.816 40.8067 140.781C48.3366 137.948 66.2839 136.191 87.5149 136.191C87.5848 136.189 87.6545 136.2 87.7196 136.222C87.7848 136.245 87.8442 136.278 87.8944 136.321C87.9445 136.363 87.9844 136.414 88.0116 136.471C88.0388 136.527 88.0528 136.588 88.0527 136.649C88.0527 136.773 87.9961 136.893 87.8952 136.981C87.7943 137.069 87.6575 137.119 87.5149 137.119C66.6944 137.119 48.5348 138.888 41.2313 141.634C41.1673 141.659 41.0984 141.671 41.0286 141.672C40.9588 141.673 40.8896 141.661 40.825 141.638C40.7603 141.615 40.7017 141.581 40.6523 141.538C40.603 141.495 40.5641 141.443 40.5378 141.387L40.4812 141.201Z"
          fill="black"
        />
        <path
          d="M102.659 83.2811C102.661 83.1686 102.709 83.0605 102.795 82.9765C102.881 82.8925 102.998 82.8382 103.126 82.8234L136.444 79.1121C136.513 79.1034 136.583 79.1068 136.651 79.1221C136.718 79.1373 136.781 79.1642 136.837 79.2011C136.892 79.2379 136.938 79.284 136.973 79.3367C137.008 79.3894 137.03 79.4477 137.039 79.508C137.051 79.6303 137.01 79.7521 136.923 79.8486C136.836 79.9452 136.711 80.0092 136.572 80.0276L103.267 83.7388C103.197 83.7475 103.126 83.7437 103.058 83.7276C102.99 83.7116 102.926 83.6837 102.871 83.6455C102.815 83.6074 102.769 83.5599 102.735 83.5057C102.701 83.4516 102.68 83.392 102.673 83.3306L102.659 83.2811Z"
          fill="black"
        />
        <path
          d="M96.2893 83.1077C96.2905 82.9988 96.3348 82.8936 96.4149 82.8102C96.4949 82.7267 96.6056 82.6701 96.7281 82.65L131.25 77.2687C131.388 77.2468 131.531 77.2735 131.648 77.3429C131.764 77.4123 131.845 77.5189 131.872 77.6398C131.894 77.7607 131.862 77.8841 131.783 77.9852C131.704 78.0862 131.584 78.1573 131.448 78.1841L96.9121 83.5283C96.8442 83.539 96.7746 83.5379 96.7073 83.525C96.6399 83.5121 96.5761 83.4877 96.5196 83.4533C96.463 83.4188 96.4148 83.3749 96.3778 83.3241C96.3407 83.2733 96.3154 83.2166 96.3035 83.1572L96.2893 83.1077Z"
          fill="black"
        />
        <path
          d="M54.0122 83.1943C54.0134 83.0976 54.0477 83.0035 54.1108 82.9241C54.1739 82.8447 54.2628 82.7836 54.3661 82.7489C55.0455 82.551 71.0253 77.8006 76.7011 76.848C83.3535 75.7099 103.112 74.6336 103.962 74.5841C104.034 74.5797 104.106 74.5884 104.175 74.6097C104.243 74.631 104.305 74.6644 104.358 74.7078C104.457 74.7974 104.513 74.9171 104.514 75.0419V77.2686C104.514 77.3859 104.463 77.4987 104.37 77.5838C104.278 77.669 104.152 77.72 104.018 77.7263C103.848 77.7263 86.5947 78.5799 80.9331 79.2356C74.394 79.9902 54.8473 83.5778 54.6491 83.6025C54.5179 83.6292 54.38 83.6107 54.264 83.5508C54.148 83.4909 54.0629 83.3942 54.0264 83.2809L54.0122 83.1943ZM103.452 75.5367C100.112 75.7346 82.8722 76.7738 76.9134 77.7635C74.0826 78.2459 68.421 79.7552 63.4954 81.116C69.5958 80.0397 77.1682 78.7655 80.7916 78.3449C85.9436 77.7511 100.607 77.0088 103.438 76.8604L103.452 75.5367Z"
          fill="black"
        />
        <path
          d="M33.5868 88.5881C33.5866 88.4959 33.6192 88.4059 33.6801 88.3306C33.7409 88.2552 33.827 88.1982 33.9265 88.1675C40.0051 86.051 46.2919 84.4237 52.7088 83.3057C59.9553 82.0435 67.3294 81.4259 74.7183 81.4625C74.7883 81.4624 74.8576 81.4747 74.922 81.4984C74.9865 81.5222 75.0449 81.5571 75.0937 81.6009C75.1425 81.6447 75.1808 81.6966 75.2063 81.7536C75.2317 81.8106 75.2439 81.8714 75.242 81.9326C75.242 81.9937 75.228 82.0543 75.2008 82.1106C75.1736 82.167 75.1338 82.218 75.0836 82.2607C75.0335 82.3034 74.9741 82.3368 74.9089 82.3591C74.8437 82.3813 74.7741 82.3919 74.7041 82.3903C60.8807 82.307 47.1691 84.5586 34.3228 89.0211C34.2582 89.0469 34.1881 89.0606 34.117 89.0614C34.046 89.0621 33.9755 89.0499 33.9102 89.0254C33.8449 89.0009 33.7862 88.9648 33.7378 88.9193C33.6894 88.8738 33.6524 88.82 33.6293 88.7613C33.6003 88.7071 33.5858 88.6479 33.5868 88.5881Z"
          fill="black"
        />
        <path
          d="M77.3797 84.048C77.3819 83.9355 77.4303 83.8274 77.5161 83.7434C77.6018 83.6594 77.7191 83.6051 77.8468 83.5903C83.5084 83.0584 89.17 82.7615 94.8316 82.7244C104.272 82.6846 113.699 83.3426 123.012 84.6913C123.082 84.7018 123.149 84.7242 123.208 84.7572C123.268 84.7903 123.32 84.8333 123.361 84.8839C123.402 84.9344 123.431 84.9916 123.446 85.0519C123.461 85.1123 123.463 85.1747 123.451 85.2356C123.441 85.2958 123.417 85.3534 123.38 85.4051C123.344 85.4568 123.295 85.5014 123.238 85.5361C123.181 85.5709 123.117 85.5951 123.049 85.6072C122.981 85.6194 122.91 85.6192 122.842 85.6068C113.603 84.2789 104.252 83.6333 94.8882 83.6769C89.2133 83.7101 83.5442 83.9992 77.9034 84.5429C77.7635 84.5537 77.6241 84.5175 77.5136 84.4417C77.4032 84.3658 77.33 84.256 77.3089 84.1346L77.3797 84.048Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_441_19283">
          <rect
            width="333"
            height="175"
            fill="white"
            transform="matrix(0 1 -1 0 175 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CarLinedIcon;
