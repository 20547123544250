import React, { useState } from "react";
import PlanifiedTask from "./dashboard/components/PlanifiedTask";
import CardInformation from "./dashboard/components/CardInformation";
import BagIcon from "./dashboard/icons/Bag";
import BasketIcon from "./dashboard/icons/Basket";
import FolderIcon from "./dashboard/icons/FolderIcon";
import WalletIcon from "./dashboard/icons/Wallet";
import RotationStock from "./dashboard/components/RotationStock";

function Sales() {
  const [selectedOption, setSelectedOption] = useState("semaine");

  const options = [
    { value: "semaine", label: "Par semaine" },
    { value: "mois", label: "Par mois" },
    { value: "annee", label: "Par année" },
  ];

  function getColorByValue(value) {
    const numericValue = parseFloat(value);

    switch (true) {
      case value.startsWith("-"):
        return "#D20000";
      case numericValue < 10:
        return "#519C66";
      case numericValue >= 10:
        return "#FFA500";
      default:
        return "#000000";
    }
  }

  return (
    <>
      <h1 className="font-bold text-xl mb-5 position-fixed">
        Ventes: Tableau de board
      </h1>
      <div className="flex flex-col md:flex-row gap-5 overflow-auto mt-2 p-5">
        <div className="flex flex-col gap-2 md:basis-4/6 flex-1">
          <PlanifiedTask />
          <div className="flex flex-col md:flex-row justify-between gap-2">
            <CardInformation
              label="Nombre de jours moyen pour vendre"
              value="16 jours"
              subValue="+5%"
              subValueColor={getColorByValue("+5%")}
              icon={BagIcon}
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            <CardInformation
              label="Prix moyen du parc"
              value="16.550 €"
              subValue="+5%"
              subValueColor={getColorByValue("+5%")}
              icon={BasketIcon}
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
          <RotationStock />
        </div>
        <div className="flex flex-col gap-3 md:basis-2/6 flex-1">
          <CardInformation
            label="Age moyen du parc"
            value="5,5 ans"
            subValue="+10%"
            subValueColor={getColorByValue("+10%")}
            icon={WalletIcon}
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <CardInformation
            label="Marge brute moyenne"
            value="4844 €"
            subValue="-10%"
            subValueColor={getColorByValue("-10%")}
            icon={WalletIcon}
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <CardInformation
            label="Cout moyen d’acquisition d’un véhicule"
            value="1600 €"
            subValue="-5%"
            subValueColor={getColorByValue("-5%")}
            icon={FolderIcon}
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <div className="h-[160px] w-full rounded-xl border border-grey2 bg-white p-5 flex items-center justify-center">
            <div className="flex flex-col items-center gap-4">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 20H2C0.89543 20 0 19.1046 0 18V6H2V18H14V20ZM18 16H6C4.89543 16 4 15.1046 4 14V2C4 0.89543 4.89543 0 6 0H18C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16ZM6 2V14H18V2H6ZM13 12H11V9H8V7H11V4H13V7H16V9H13V12Z"
                  fill="#2E3A59"
                />
              </svg>

              <span className="text-[#8B8D97] mb-2">
                Ajouter une indicateur
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sales;
