import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useParams } from "react-router";
import Swal from "sweetalert2";
import StepsNav from "./common/stepsNavigator";
import ImagesOverview from "./common/subComponents/imagesOverview";
import Stepper from "./common/Stepper";
import DamageImagesUpload from "./common/damageImagesUpload";
import DamagedCarsDisplay from "./common/DamagedCarsDisplay";
import FormInformation from "./common/formInformation";
import CarouselPreview from "../Carousel";
import {
  addCar,
  clearCarDamageImages,
  editCar,
  getCarCount,
  GetCarDamageImages,
} from "../../Redux/cars/actions";
import "./style.css";
import Car3dViewer from "../AiTools/subComponents/3DViewer";
import { ExternalState } from "./common/subComponents/externalState";
import { InternalState } from "./common/subComponents/internalState";
import Webcam from "react-webcam";
import CameraButtons from "./common/subComponents/cameraController";
import VideoRecordingIcon from "./common/subComponents/videoRecordingIcon/videoRecordingIcon";
import { dispatchNextStep } from "../../Redux/camera/actions";
import CustomizedSteppers from "../stepper/horizentalStepper/horizentalStepper";
import VerticalStepper from "../stepper/verticalStepper/verticalStepper";
import { baseSteps, steps } from "./common/steps";
import BaseStepper from "../stepper/base/baseStepper";
import ConnectionModal from "../stepper/modals/ConnectionModal";
import FixingSection from "../carPricing/FixingSection";
import PreviewSection from "../carPricing/PreviewSection";
import ConfirmSection from "../dataUpload/ConfirmSection";
import CheckSection from "../dataUpload/CheckSection";
import ConfirmUpload from "../dataUpload/modals/ConfirmUpload";
import PreviewCar from "../previewCar/PreviewCar";
import Inactive from "../inactive/models/Inactive";
import { REMAINING_ANALYSIS_RETRY_NUMBER } from "../../Redux/cars/actionTypes";

function CarForm({ isEdit }) {
  // Camera component
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isCaptured, setIsCaptured] = useState(false);

  const handleStartCapture = () => {
    setIsRecording(true);
    setRecordedChunks([]);
    startRecording();
  };

  const handleStopCapture = () => {
    setIsRecording(false);
    stopRecording();
  };

  const handleRetakeCapture = () => {
    setIsCaptured(false);
    setRecordedChunks([]);
  };

  const handleCloseCamera = () => {
    setIsRecording(false);
    setIsCaptured(false);
    setRecordedChunks([]);

    // Stop the camera stream
    if (
      webcamRef.current &&
      webcamRef.current.video &&
      webcamRef.current.video.srcObject
    ) {
      const tracks = webcamRef.current.video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
    // Swal.fire({
    //   title: `Voiture ajoutée avec succés`,
    //   confirmButtonText: '<a href="/addCar">Ajouter une voiture</a>',
    //   cancelButtonText: '<a href="/cars">Aller au tableau des voitures</a>',
    //   showCancelButton: true,
    //   showCloseButton: true,
    // });
    dispatch(dispatchNextStep(next));

    setNext((activeStep) => activeStep + 1);
  };

  const startRecording = () => {
    dispatch(dispatchNextStep(next));
    const stream = webcamRef.current.video.srcObject;
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });

    mediaRecorderRef.current.ondataavailable = handleDataAvailable;
    mediaRecorderRef.current.onstop = handleStop;

    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setRecordedChunks((prev) => prev.concat(event.data));
    }
  };

  const handleStop = () => {
    setIsCaptured(true);
  };
  const videoConstraints = {
    width: 1280,
    height: 850,
    facingMode: "environment", // or 'environment' for the rear camera
  };
  useEffect(() => {
    // Cleanup: Stop the camera stream when unmounting
    return () => {
      if (
        webcamRef.current &&
        webcamRef.current.video &&
        webcamRef.current.video.srcObject
      ) {
        const tracks = webcamRef.current.video.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  /// Camera Component
  const dispatch = useDispatch();
  const isNextConfirmed = useSelector((state) => state).cars.isNextConfirmed;
  const success = useSelector((state) => state).cars.success;
  const formInformation = useSelector((state) => state).formReducer;
  const edittingCar = useSelector((state) => state).cars.car;
  const overviewImgs = useSelector((state) => state).formReducer.overviewImgs;
  const damagedCarImages = useSelector((state) => state).cars.damagedCarImages;
  const analysedCarImages = useSelector((state) => state).cars
    .analysedCarImages;
  const [next, setNext] = useState(0);

  const params = useParams();

  const [analysisStep, setAnalysisStep] = useState(0);
  const [openOBD2Connector, setOpenOBD2Connector] = useState(false);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [inactive, setInactive] = useState(false);

  const { retries } = useSelector((state) => state.cars);

  const timerRef = useRef(null);

  const formIsFilledRedux =
    !String(formInformation.make) ||
    !String(formInformation.model) ||
    !String(formInformation.price) ||
    !String(formInformation.overviewYear) ||
    !String(formInformation.location) ||
    !String(formInformation.km) ||
    !String(formInformation.seat) ||
    !String(formInformation.gearBox) ||
    !String(formInformation.fuel) ||
    !String(formInformation.outside) ||
    !String(formInformation.inside) ||
    !String(formInformation.numberCV) ||
    !String(formInformation.averageConsumption) ||
    !String(formInformation.immatricule);
  // open and close the feature dropdown

  useEffect(() => {
    dispatch(getCarCount());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(clearCarDamageImages());
    dispatch({ type: "RESET_FORM" });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // If user came back from step2 the form shouldn't reset.
    if (isNextConfirmed) {
      if (Object.keys(edittingCar).length > 0) {
        dispatch({ type: "INIT_FORM", payload: edittingCar });
      }
    }
  }, [edittingCar]);

  const handleNext = async (e) => {
    dispatch(dispatchNextStep(next));
    e.preventDefault();
    if (next === 1) {
      dispatch(dispatchNextStep(next));
      try {
        setNext((activeStep) => activeStep + 1);
      } catch (error) {
        Swal.fire({
          title: `Votre voiture a été ajoutée, mais une erreur est survenue l'ors de l'analyses des images.`,
          cancelButtonText: '<a href="/cars">Aller au Tableau des Voitures</a>',
          showCloseButton: true,
        });
      }
    } else if (next === 0) {
      dispatch(dispatchNextStep(next));
      if (success && edittingCar?._id) {
        dispatch(
          editCar(
            success?.id || success?.car?._id || edittingCar?._id,
            formInformation
          )
        );
      } else dispatch(addCar(formInformation));
      success || edittingCar?._id
        ? successAddCar()
        : Swal.fire({
            title: `Une erreur est survenue!`,
            confirmButtonText: "<p>Compris!</p>",
            showCloseButton: true,
          });
    } else if (next === 2) {
      setNext((activeStep) => activeStep + 1);
    } else {
      setNext((activeStep) => activeStep + 1);
    }
  };
  const successAddCar = () => {
    setNext((activeStep) => activeStep + 1);
    Swal.fire({
      title:
        success?.id || success?.car?._id || edittingCar?._id
          ? "Voiture modifiée avec succés"
          : `Voiture ajoutée avec succés`,
      /*confirmButtonText: '<a href="/addCar">Ajouter une voiture</a>',
      cancelButtonText: '<a href="/cars">Aller au tableau des voitures</a>',
      showCancelButton: true,
      showCloseButton: true,*/
      confirmButtonText: "<p>Ok !</p>",
      showCloseButton: true,
    });
  };

  const goBack = (e) => {
    dispatch(dispatchNextStep(next));
    e.preventDefault();
    setNext((activeStep) => activeStep - 1);
  };

  useEffect(() => {
    if (isEdit && params.id) {
      dispatch(GetCarDamageImages(params.id));
    }
    // eslint-disable-next-line
  }, [isEdit]);

  const handleStepAnalysisNext = () => {
    setAnalysisStep(Math.min(steps.length - 1, analysisStep + 1));
    if (analysisStep === 1) {
      setOpenOBD2Connector(true);
    }
  };

  const handleStepAnalysisBack = () => {
    setAnalysisStep(Math.max(0, analysisStep - 1));
  };

  const invokeHelp = () => {
    switch (analysisStep) {
      case 0:
        Swal.fire({
          title: "Help",
          text: "This is the help for the first step",
        });
        break;
      case 1:
        Swal.fire({
          title: "Help",
          text: "This is the help for the second step",
        });
        break;
      case 2:
        Swal.fire({
          title: "Help",
          text: "This is the help for the third step",
        });
        break;
      case 3:
        Swal.fire({
          title: "Help",
          text: "This is the help for the fourth step",
        });
        break;
      case 4:
        Swal.fire({
          title: "Help",
          text: "This is the help for the fifth step",
        });
        break;
      default:
        break;
    }
  };
  const handleOpenConfirmUpload = () => {
    setOpenUploadModal(true);
  };

  const handleMouseMove = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setInactive(true);
    }, 900000);
  };

  const handleModalClose = () => {
    setInactive(false);
    clearTimeout(timerRef.current);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(timerRef.current);
    };
  }, []);

  const handleRetry = () => {
    dispatch({ type: REMAINING_ANALYSIS_RETRY_NUMBER, payload: retries - 1 });
    setAnalysisStep(0);
  };

  return (
    <div className="flex h-[100%] flex-col overflow-y-scroll">
      <div className="mb-2">
        <CustomizedSteppers
          steps={baseSteps}
          activeStep={next <= 0 ? next + 1 : next}
        />
      </div>
      {(() => {
        switch (next) {
          case 0:
            return (
              <div className="flex flex-col xl:flex-row h-[calc(100vh-100px)] justify-between gap-8 pb-10 xl:pb-1">
                <div className="bg-white0 w-full mx-auto xl:w-[400px] flex-shrink-0  md:mb-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3 overflow-hidden">
                  <div className="lg:rounded-md xxl:rounded-md h-[calc(100%-120px)] overflow-y-auto">
                    <div className="p-5 border border-grey2 rounded-lg sm:border-0">
                      <h1 className="font-bold text-[20px] text-lightblue2 mb-2">
                        Aperçu des images
                      </h1>
                      <ImagesOverview overviewImgs={overviewImgs} />
                      <FormInformation id={params.id} />
                    </div>
                  </div>
                  <Stepper
                    next={next}
                    handleNext={handleNext}
                    stateRedux={formIsFilledRedux}
                    addCar={addCar}
                    editCar={editCar}
                    isNextConfirmed={isNextConfirmed}
                  />
                </div>
                <div className="max-h-full h-fit xl:flex lg:rounded-md min-w-0 flex-grow drop-shadow-lg">
                  <CarouselPreview data={overviewImgs ?? []} />
                </div>
              </div>
            );

          case 1:
            return (
              <div className="flex flex-col xl:flex-row h-full justify-between gap-8 pb-20 xl:pb-1">
                <div className="mx-auto bg-white0 w-full xl:w-[400px] flex-shrink-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3 overflow-hidden">
                  <div className="lg:rounded-md xxl:rounded-md h-[calc(100%-140px)] overflow-y-auto">
                    <div className="p-5 border border-grey2 rounded-lg">
                      <h1 className="font-bold text-[20px] text-lightblue2 py-3">
                        + Ajouter des photos
                      </h1>
                      <DamageImagesUpload isEdit={isEdit} id={params.id} />
                    </div>
                  </div>
                  <Stepper
                    next={next}
                    handleNext={handleNext}
                    stateRedux={false}
                    addCar={addCar}
                    editCar={editCar}
                    isNextConfirmed={true}
                  />
                </div>
                <div className="xxl:block bg-white0  h-fit drop-shadow-lg xxl:w-screen rounded-md md:border md:border-grey2 pt-10 pb-20 px-10">
                  <h3 className="text-[19px] text-warning1 font-bold mb-[25px]">
                    Car Preview
                  </h3>
                  <p className="text-[20px] font-bold mb-[15px]">
                    Click each point to see the preview
                  </p>
                  <div>
                    <p className=" font-medium text-[18px] text-blue1">
                      External State
                    </p>
                    <ExternalState images={damagedCarImages} />
                    <p className=" font-medium text-[18px] text-blue1">
                      Internal State
                    </p>
                    <InternalState images={damagedCarImages} />
                  </div>
                </div>
              </div>
            );

          case 2:
            return (
              <div className="flex flex-col xl:flex-row h-fit justify-between gap-8 pb-10 xl:pb-1">
                <div className=" mx-auto h-fit bg-white0 w-full xl:w-[400px] flex-shrink-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3 xxl:overflow-hidden">
                  <div className="lg:rounded-md xxl:rounded-md">
                    <DamagedCarsDisplay />
                  </div>
                  <Stepper
                    next={next}
                    handleNext={handleNext}
                    stateRedux={formIsFilledRedux}
                    addCar={addCar}
                    editCar={editCar}
                    isNextConfirmed={isNextConfirmed}
                  />
                </div>
                <div className="xxl:block bg-white0 xl:w-2/3 drop-shadow-lg xxl:w-screen h-fit rounded-md md:border md:border-grey2 pt-10 pb-20 px-10 xxl:overflow-hidden">
                  <h3 className="text-[19px] text-warning1 font-bold mb-[25px]">
                    Car Preview
                  </h3>
                  <p className="text-[20px] font-bold mb-[15px]">
                    Click each point to see the preview
                  </p>
                  <div>
                    <p className="my-[20px] font-medium text-[18px] text-blue1">
                      External State
                    </p>
                    <ExternalState images={analysedCarImages} />
                    <p className="my-[10px] font-medium text-[18px] text-blue1">
                      Internal State
                    </p>
                    <InternalState images={analysedCarImages} />
                  </div>
                </div>
              </div>
            );

          case 3:
            return (
              <div className="flex flex-col xl:flex-row  justify-between gap-8  pb-10 xl:pb-1">
                <div className="bg-white0 w-full xl:w-[400px] flex-shrink-0 sm:mx-auto rounded-md md:border md:border-grey2 overflow-hidden relative">
                  <StepsNav next={next} back={goBack} />
                  <div className="flex justify-center lg:rounded-md xxl:rounded-md overflow-y-auto relative flex-col">
                    {isCaptured ? (
                      <video
                        controls
                        src={URL.createObjectURL(
                          new Blob(recordedChunks, { type: "video/webm" })
                        )}
                      />
                    ) : (
                      <Webcam
                        videoConstraints={videoConstraints}
                        ref={webcamRef}
                        onUserMediaError={(error) =>
                          console.error("Error capturing media: ", error)
                        }
                        onDataAvailable={handleDataAvailable}
                      />
                    )}
                    {isRecording && (
                      <div className="absolute top-0 right-0 left-0 flex text-[14px] overflow-hidden w-14 items-center justify-center rounded-full m-3 flex-wrap bg-white0 drop-shadow-md">
                        <VideoRecordingIcon isRecording={isRecording} />
                      </div>
                    )}
                    <CameraButtons
                      isCaptured={isCaptured}
                      isRecording={isRecording}
                      handleStartCapture={handleStartCapture}
                      handleStopCapture={handleStopCapture}
                      handleRetakeCapture={handleRetakeCapture}
                      handleCloseCamera={handleCloseCamera}
                    />
                  </div>
                </div>
                <div className="xxl:block bg-white0 xxl:w-2/3 drop-shadow-lg sm:p-2 xl:w-screen h-fit rounded-md md:border md:border-grey2 pt-10 pb-20 px-10">
                  <h3 className="text-[19px] text-warning1 font-bold mb-[25px]">
                    Car Preview
                  </h3>
                  <p className="text-[20px] font-bold mb-[15px]">
                    Click each point to see the preview
                  </p>
                  <Car3dViewer />
                </div>
              </div>
            );

          case 4:
            return (
              <div className="flex flex-col xl:flex-row  justify-between gap-8">
                <div className="bg-white0 w-full h-fit  mx-auto xl:w-[400px] flex-shrink-0 sm:mb-4 md:mb-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3 xxl:overflow-hidden">
                  <VerticalStepper
                    steps={steps}
                    stepAnalysis={analysisStep}
                    handleBack={handleStepAnalysisBack}
                    handleNext={handleStepAnalysisNext}
                    goBack={goBack}
                  />
                  <Stepper
                    next={next}
                    handleNext={handleNext}
                    stateRedux={!(analysisStep === steps.length - 1)}
                    addCar={addCar}
                    editCar={editCar}
                    isNextConfirmed={false}
                    handleRetry={analysisStep === 5 && handleRetry}
                    retries={retries}
                  />
                </div>
                <div className="sm:p-2 max-h-full h-fit xl:flex lg:rounded-md min-w-0 flex-grow drop-shadow-lg flex-col pb-20">
                  <BaseStepper
                    stepAnalysis={analysisStep}
                    handleStepAnalysisNext={handleStepAnalysisNext}
                    invokeHelp={invokeHelp}
                  />
                </div>
              </div>
            );

          case 5:
            return (
              <div className="flex flex-col xl:flex-row justify-between gap-8 pb-10 xl:pb-1">
                <div className="bg-white0 w-full h-full sm:h-fit mx-auto xl:w-[400px] flex-shrink-0 sm:mb-4 md:mb-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3 overflow-hidden">
                  <FixingSection goBack={goBack} />
                  <Stepper
                    next={next}
                    handleNext={handleNext}
                    stateRedux={false}
                    addCar={addCar}
                    editCar={editCar}
                    isNextConfirmed={false}
                  />
                </div>
                <div className="max-h-full h-fit xl:flex lg:rounded-md min-w-0 flex-grow drop-shadow-lg flex-col  pb-10 xl:pb-1">
                  <PreviewSection />
                </div>
              </div>
            );
          case 6:
            return (
              <div className="flex flex-col xl:flex-row justify-between gap-8 pb-10 xl:pb-1">
                <div className="bg-white0 w-full h-full sm:h-fit mx-auto xl:w-[400px] flex-shrink-0 sm:mb-4 md:mb-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3 overflow-hidden">
                  <ConfirmSection goBack={goBack} />
                  <Stepper
                    next={next}
                    handleNext={handleOpenConfirmUpload}
                    stateRedux={false}
                    addCar={addCar}
                    editCar={editCar}
                    isNextConfirmed={false}
                    customText={"Ok to Upload !"}
                  />
                </div>

                <div className="max-h-full h-fit xl:flex lg:rounded-md min-w-0 flex-grow drop-shadow-lg flex-col  pb-10 xl:pb-1">
                  <CheckSection handleNext={handleNext} goBack={goBack} />
                </div>
              </div>
            );
          case 7:
            return (
              <div className="w-full">
                <PreviewCar />
              </div>
            );

          default:
            return null;
        }
      })()}

      <Inactive isOpen={inactive} onClose={handleModalClose} />
      <ConnectionModal
        isOpen={openOBD2Connector}
        onClose={() => {
          setOpenOBD2Connector(false);
        }}
      />

      <ConfirmUpload
        isOpen={openUploadModal}
        onClose={() => {
          setOpenUploadModal(false);
        }}
      />
    </div>
  );
}

export default CarForm;
