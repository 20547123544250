import React, { useState, useEffect, useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import { getAnalyzedCarImages } from "../../../Redux/cars/actions";
import { DamagedImageSamples } from "../../../Constants/cars";
import { SocketContext } from "../../../Context/socket";
const DamagedCarsDisplay = () => {
  const socketio = useContext(SocketContext);
  const [imagesAreProcessing, setImagesAreProcessing] = useState(true);
  const [imagesAreProcessed, setImagesAreProcessed] = useState(true);
  const carId = useSelector((state) => state).cars?.car?._id;
  const dispatch = useDispatch();
  const analysedImages = useSelector((state) => state).cars.damagedCarImages;
  const analysedCarImagesError = useSelector((state) => state).cars
    .analysedCarImagesError;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  console.log("+++++++++++++++DamagedCarsDisplay", analysedImages);
  useEffect(() => {
    console.log("analysedCarImagesError", analysedCarImagesError);
    socketio.on("notification", (isNotified) => {
      console.log("isNotified", isNotified);
      console.log(
        "isNotified.notifServiceIndicator",
        isNotified.notifServiceIndicator
      );
      if (isNotified.notifServiceIndicator === "cdd-processing") {
        setImagesAreProcessing(true);
        setImagesAreProcessed(false);
      } else if (isNotified.notifServiceIndicator === "cdd-processed") {
        setImagesAreProcessed(true);
        setImagesAreProcessing(false);
      }
    });
    if (imagesAreProcessed) {
      dispatch(getAnalyzedCarImages(carId));
      setImagesAreProcessing(false);
    }
    return () => {
      socketio.off("notification");
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="h-[300px] lg:h-[420px] xl:h-[420px] xxl:h-[420px] md:h-[420px]">
      {imagesAreProcessing ? (
        <div className="my-10">
          <div className="self-center inline-block w-full h-full flex-col space-y-5 bg-[#EEEEEE] p-10">
            <div className="mt-2 flex flex-col items-center justify-center ">
              <div className=" flex items-center justify-center">
                <div className="block py-2 rounded text-sm uppercase tracking-wide font-semibold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 200 200"
                    fill="none"
                    color="#4368B1"
                  >
                    <defs>
                      <linearGradient id="spinner-secondHalf">
                        <stop
                          offset="0%"
                          stop-opacity="0"
                          stop-color="currentColor"
                        />
                        <stop
                          offset="100%"
                          stop-opacity="0.5"
                          stop-color="currentColor"
                        />
                      </linearGradient>
                      <linearGradient id="spinner-firstHalf">
                        <stop
                          offset="0%"
                          stop-opacity="1"
                          stop-color="currentColor"
                        />
                        <stop
                          offset="100%"
                          stop-opacity="0.5"
                          stop-color="currentColor"
                        />
                      </linearGradient>
                    </defs>
                    <g stroke-width="8">
                      <path
                        stroke="url(#spinner-secondHalf)"
                        d="M 4 100 A 96 96 0 0 1 196 100"
                      />
                      <path
                        stroke="url(#spinner-firstHalf)"
                        d="M 196 100 A 96 96 0 0 1 4 100"
                      />

                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        d="M 4 100 A 96 96 0 0 1 4 98"
                      />
                    </g>
                    <animateTransform
                      from="0 0 0"
                      to="360 0 0"
                      attributeName="transform"
                      type="rotate"
                      repeatCount="indefinite"
                      dur="1300ms"
                    />
                  </svg>
                </div>
                <p className="block bg-gray-300 text-[#282F75] px-4 rounded text-sm font-normal text-[18px]">
                  Loading
                </p>
              </div>
              <p className="text-[#858585] text-[18px] text-center">
                Scanning Photos to detect damage
              </p>
            </div>
          </div>
        </div>
      ) : imagesAreProcessed ? (
        <>
          <Swiper
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            pagination={{
              type: "fraction",
            }}
            modules={[FreeMode, Navigation, Thumbs, Pagination]}
            className="relative flex flex-col justify-center items-center damage-carousel-swiper"
            slidesPerView={1}
          >
            {analysedImages.map((imageData, index) => (
              <SwiperSlide key={`analysed-image-slide-${index}`}>
                <div className="h-full flex flex-col justify-center items-center">
                  <img
                    className="mx-auto w-full object-contain block md:max-h-[500px]"
                    src={imageData.image_link}
                    alt={``}
                  />
                  {imageData.nb_damages && (
                    <p className="text-center z-10 absolute top-4 bg-black1 text-white0 px-4 py-2 bg-opacity-70 right-4 rounded">
                      {imageData.nb_damages} dégat(s) detecté
                    </p>
                  )}
                  <div className="absolute bg-white0 p-2 right-4 bottom-4 rounded-md border border-grey1">
                    <img
                      src={
                        DamagedImageSamples.find(
                          (part) => part.namingRule === imageData.filename
                        )?.image
                      }
                      alt="damage-parts"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            freeMode={true}
            watchSlidesProgress={true}
            navigation
            modules={[FreeMode, Navigation, Thumbs]}
            className="relative flex flex-col justify-center items-center mt-4 damage-carousel-swiper-bottom"
            slidesPerView={4}
            spaceBetween={10}
          >
            {analysedImages.map((imageData, index) => (
              <SwiperSlide key={index} className="!h-auto self-auto">
                <div className="relative flex flex-col justify-center items-center h-full">
                  <img
                    alt="s3-link"
                    className="mx-auto w-full object-contain block md:max-h-[500px]"
                    src={imageData.image_link}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : analysedCarImagesError ? (
        <div className="my-10">
          <div className="self-center inline-block w-full h-full flex-col items-center justify-center space-y-5 bg-[#EEEEEE] p-10">
            <div className="flex flex-col items-center justify-center ">
              <p className="block text-[#db2b2b] px-4 rounded text-sm font-normal text-[18px] mt-2 ">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  fill="#db2b2b"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.266 20.9981H2.73301C2.37575 20.9981 2.04563 20.8075 1.867 20.4981C1.68837 20.1887 1.68838 19.8075 1.86701 19.4981L11.133 3.49811C11.3118 3.18923 11.6416 2.99902 11.9985 2.99902C12.3554 2.99902 12.6852 3.18923 12.864 3.49811L22.13 19.4981C22.3085 19.8074 22.3086 20.1883 22.1303 20.4977C21.9519 20.807 21.6221 20.9977 21.265 20.9981H21.266ZM11 15.9981V17.9981H11.933H11.998H12.063H12.998V15.9981H11ZM11 8.99811V13.9981H13V8.99811H11Z"
                    fill="#db2b2b"
                  />
                </svg>
              </p>
            </div>
            <p className="tetext-[#FFFFF] text-[16px] text-center">
              Une erreur est survenue lors du traitement d'une image
            </p>
          </div>
        </div>
      ) : (
        <div className="mt-2 flex flex-col items-center justify-center ">
          <p className="text-[#858585] text-[18px] text-center">
            Pas de photos a traité!
          </p>
        </div>
      )}
    </div>
  );
};
export default DamagedCarsDisplay;
