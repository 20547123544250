import React from "react";
import VerticalStepper from "../../stepper/verticalStepper/verticalStepper";
import { steps } from "../common/steps";
import BaseStepper from "../CampaignStepper/baseStepper";
import ConfirmChosenCar from "../CampaignStepper/modals/ConfirmChosenCar";

function ConfigureCampaign({
  goBack,
  chosenConfiguration,
  handleOpenChooseConfigurationModal,
  campaignStep,
  handleStepBack,
  handleStepNext,
  handleGoToAnalytics,
  chosenCars,
}) {
  return (
    <div className="flex flex-row  justify-between gap-8">
      <div className="bg-white0 w-full h-full mx-auto sm:w-[400px] flex-shrink-0 sm:mb-4 md:mb-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-3 overflow-hidden">
        <VerticalStepper
          steps={steps}
          stepAnalysis={campaignStep}
          handleBack={handleStepBack}
          handleNext={handleStepNext}
          goBack={goBack}
        />
      </div>
      <div className="hidden max-h-full h-fit xl:flex lg:rounded-md min-w-0 flex-grow drop-shadow-lg flex-col">
        <BaseStepper
          activeStep={campaignStep}
          chosenConfiguration={chosenConfiguration}
          handleOpenChooseConfigurationModal={
            handleOpenChooseConfigurationModal
          }
          handleGoToAnalytics={handleGoToAnalytics}
        />
      </div>
      <ConfirmChosenCar
        isOpen={campaignStep === 0}
        onContinue={handleStepNext}
        onBack={goBack}
        cars={chosenCars}
      />
    </div>
  );
}

export default ConfigureCampaign;
