import React, { useState } from "react";
import StatusModal from "../modals/StatusModal";
import StepHeader from "../base/StepHeader";

function BrakeTestStep({ handleNext, invokeHelp, isOBD2Connected }) {
  const [state, setState] = useState(1);
  const [brakeTestModalOpen, setBrakeTestModalOpen] = useState(false);
  const changeState = () => {
    switch (state) {
      case 1:
        setState(2);
        break;
      case 2:
        setBrakeTestModalOpen(true);
        setState(3);
        break;
      case 3:
        setBrakeTestModalOpen(false);
        setState(1);
        break;
      default:
        break;
    }
  };

  const goNext = () => {
    setBrakeTestModalOpen(false);
    handleNext();
  };
  return (
    <>
      <div className="rounded bg-white flex-col">
        <StepHeader invokeHelp={invokeHelp} isOBD2Connected={isOBD2Connected} />
        <div className="w-full bg-gray-100 h-0.5 mb-2"></div>
        <div className=" rounded-b overflow-hidden">
          <div className="p-2">
            <p className="text-darkblue1 text-xl font-semibold">Brake Test:</p>
            <ul className="list-decimal ms-4 text-darkblue1 text-md">
              <li className="m-2">
                While driving at a moderate speed in a safe area, apply the
                brakes firmly but smoothly. Tap on the Start button below.
              </li>
              <li className="m-2">
                While driving at a moderate speed in a safe area, apply the
                brakes firmly but smoothly.
              </li>
              <li className="m-2">
                Ensure the CAN system records the brake system status and
                response.
              </li>
            </ul>
          </div>
          <div className="relative rounded-b overflow-hidden ">
            <div className="flex justify-between items-center w-[320px] bg-white rounded absolute top-5 left-1/2 transform -translate-x-1/2 p-4 text-darkblue1 text-center">
              {state === 2 && (
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.375 15.5C2.375 12.9041 3.14477 10.3665 4.58696 8.20815C6.02915 6.04975 8.079 4.36749 10.4773 3.37409C12.8756 2.38069 15.5146 2.12077 18.0606 2.6272C20.6066 3.13363 22.9452 4.38367 24.7808 6.21923C26.6163 8.05479 27.8664 10.3934 28.3728 12.9394C28.8792 15.4854 28.6193 18.1244 27.6259 20.5227C26.6325 22.921 24.9503 24.9708 22.7919 26.413C20.6335 27.8552 18.0959 28.625 15.5 28.625C15.2099 28.625 14.9317 28.7402 14.7266 28.9454C14.5215 29.1505 14.4062 29.4287 14.4062 29.7188C14.4062 30.0088 14.5215 30.287 14.7266 30.4922C14.9317 30.6973 15.2099 30.8125 15.5 30.8125C18.5285 30.8125 21.489 29.9144 24.0072 28.2319C26.5253 26.5493 28.4879 24.1578 29.6469 21.3598C30.8059 18.5619 31.1091 15.483 30.5183 12.5127C29.9274 9.54235 28.4691 6.81393 26.3276 4.67243C24.1861 2.53094 21.4577 1.07257 18.4873 0.481732C15.517 -0.109104 12.4382 0.194135 9.64016 1.3531C6.84217 2.51207 4.45068 4.47471 2.76812 6.99284C1.08556 9.51096 0.1875 12.4715 0.1875 15.5C0.1875 15.7901 0.302734 16.0683 0.507852 16.2734C0.71297 16.4785 0.991169 16.5938 1.28125 16.5938C1.57133 16.5938 1.84953 16.4785 2.05465 16.2734C2.25977 16.0683 2.375 15.7901 2.375 15.5Z"
                    fill="#282F75"
                  />
                </svg>
              )}
              <span className="text-darkblue1" style={{ fontWeight: 600 }}>
                Brake Data Test
              </span>
              <button
                onClick={changeState}
                className={`px-2 py-1 ${
                  state === 1 ? "bg-darkgreen " : "bg-[red]"
                } text-white rounded w-[64px]`}
              >
                {state === 1 ? "Start" : "Stop"}
              </button>
            </div>
            <img
              src="./steps/step5.png"
              alt="OBD2"
              className="w-full h-[650px] object-fit mt-2"
            />
          </div>
        </div>
      </div>
      <StatusModal
        isOpen={brakeTestModalOpen}
        goNext={goNext}
        retake={changeState}
        success={true}
        header={"Gathering Brake Data "}
      />
    </>
  );
}

export default BrakeTestStep;
