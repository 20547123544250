import React from "react";
import PassedIcon from "./icons/PassedIcon";
import WarningIcon from "./icons/WarningIcon";
import BrakingSystemCarIcon from "./icons/BrakingSystemCarIcon";

const BrakeSystemReport = [
  {
    title: "Distance de freinage (50 km/h - 0)",
    passed: true,
    value: "40 m",
  },
  {
    title: "Sensation de la pédale de frein",
    passed: true,
    value: "Stable",
  },
  {
    title: "Fonction de l'ABS",
    passed: true,
    value: "Normal",
  },
  {
    title: "Répartition du freinage",
    passed: false,
    value: "60/40 F/R",
  },
  {
    title: "Résistance à la perte de freinage",
    passed: true,
    value: "Bien",
  },
  {
    title: "Frein à main",
    passed: true,
    value: "Maintient à 20°",
  },
  {
    title: "État des conduites de frein",
    passed: true,
    value: "Aucune fuite",
  },
];
const brakingSystem = {
  frontBrakingDisk: 90,
  backBrakingDisk: 90,
  brakingDisk: 89,
  brakingLiquid: 90,
};
function BrakeSystemDetailed() {
  return (
    <div className="flex flex-col p-5 justify-center items-center">
      <div className="flex flex-col mx-10 flex justify-center items-center">
        <BrakingSystemCarIcon />
        <div className="flex items-center xl:w-[600px] w-[470px] justify-between">
          <div>
            <h1 className="text-center font-bold m-2 ">
              Plaquettes de frein avant
            </h1>
            <div className="flex items-center gap-2 justify-end">
              <div className="bg-gray-200 rounded-full h-1.5 w-[150px] dark:bg-gray-700">
                <div
                  className={`h-1.5 rounded-full bg-[#118D57]`}
                  style={{ width: `${brakingSystem.frontBrakingDisk}%` }}
                ></div>
              </div>

              <small className="font-bold">
                {brakingSystem.frontBrakingDisk}%
              </small>
            </div>
          </div>
          <div>
            <h1 className="text-center font-bold m-2">
              Plaquettes de frein arrière
            </h1>
            <div className="flex items-center gap-2 justify-start">
              <small className="font-bold">
                {brakingSystem.frontBrakingDisk}%
              </small>
              <div className="bg-gray-200 rounded-full h-1.5 w-[150px] dark:bg-gray-700">
                <div
                  className={`h-1.5 rounded-full bg-[#118D57]`}
                  style={{ width: `${brakingSystem.backBrakingDisk}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5 my-10 w-full px-10">
        <div class="w-full flex justify-between gap-3 px-3 items-center">
          <span className="font-bold">Disques de frein</span>
          <div className="flex items-center gap-2">
            <div className="bg-gray-200 rounded-full h-2 w-[250px] dark:bg-gray-700">
              <div
                className={`h-2 rounded-full bg-[#118D57]`}
                style={{ width: `${brakingSystem.brakingDisk}%` }}
              ></div>
            </div>

            <small className="font-bold"> {brakingSystem.brakingDisk}</small>
          </div>
        </div>
        <div class="w-[full] flex justify-between gap-3 px-3 items-center ">
          <span className="font-bold">Liquide de frein</span>
          <div className="flex items-center gap-2">
            <div className="bg-gray-200 rounded-full h-2 w-[250px] dark:bg-gray-700">
              <div
                className={`h-2 rounded-full bg-[#118D57]`}
                style={{ width: `${brakingSystem.brakingLiquid}%` }}
              ></div>
            </div>

            <small className="font-bold"> {brakingSystem.brakingLiquid}</small>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-5 px-5 my-10">
        {BrakeSystemReport.map((report) => (
          <div className="w-full flex justify-between items-center">
            <h1 className="font-bold text-l">{report.title}</h1>
            <div className="flex gap-2 items-center">
              <p>{report.value}</p>
              {report.passed ? <PassedIcon /> : <WarningIcon />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BrakeSystemDetailed;
