import { useSelector } from "react-redux";
import { useMemo } from "react";
import { Popover } from "@headlessui/react";
import carTopImage from "./../../../../Assets/car-images/car-top.svg";
import carFrontImage from "./../../../../Assets/car-images/car-front.svg";
import carLeftImage from "./../../../../Assets/car-images/car-left.svg";
import carBackImage from "./../../../../Assets/car-images/car-back.svg";
import carRightImage from "./../../../../Assets/car-images/car-right.svg";
import {
  CarTopStates,
  CarFrontStates,
  CarLeftStates,
  CarBackStates,
  CarRightStates,
  CarInitialStates,
} from "../../../../Constants/cars";

export const ExternalState = ({ images }) => {
  const carTopStates = useMemo(
    () =>
      CarTopStates.reduce((uploadedImages, item) => {
        const damagedImages = images.filter(
          (file) => file.filename?.includes(item.name) && !!file.image_link
        );
        return damagedImages?.length
          ? [...uploadedImages, { ...item, images: damagedImages }]
          : uploadedImages;
      }, []),
    [images]
  );

  const carFrontStates = useMemo(
    () =>
      CarFrontStates.reduce((uploadedImages, item) => {
        const damagedImages = images.filter(
          (file) => file.filename?.includes(item.name) && !!file.image_link
        );
        return damagedImages?.length
          ? [...uploadedImages, { ...item, images: damagedImages }]
          : uploadedImages;
      }, []),
    [images]
  );

  const carLeftStates = useMemo(
    () =>
      CarLeftStates.reduce((uploadedImages, item) => {
        const damagedImages = images.filter(
          (file) => file.filename?.includes(item.name) && !!file.image_link
        );
        return damagedImages?.length
          ? [...uploadedImages, { ...item, images: damagedImages }]
          : uploadedImages;
      }, []),
    [images]
  );

  const carBackStates = useMemo(
    () =>
      CarBackStates.reduce((uploadedImages, item) => {
        const damagedImages = images.filter(
          (file) => file.filename?.includes(item.name) && !!file.image_link
        );
        return damagedImages?.length
          ? [...uploadedImages, { ...item, images: damagedImages }]
          : uploadedImages;
      }, []),
    [images]
  );

  const carRightStates = useMemo(
    () =>
      CarRightStates.reduce((uploadedImages, item) => {
        const damagedImages = images.filter(
          (file) => file.filename?.includes(item.name) && !!file.image_link
        );
        return damagedImages?.length
          ? [...uploadedImages, { ...item, images: damagedImages }]
          : uploadedImages;
      }, []),
    [images]
  );

  return (
    <>
      <div className="flex justify-center">
        <div className="relative">
          <img src={carTopImage} alt="car-top" />
          {carTopStates.map(({ className, images }, index) => {
            return (
              <Popover key={index} className={`absolute ${className}`}>
                <Popover.Button>
                  <div className="bg-[#282F75] w-[10px] h-[10px] rounded-full" />
                </Popover.Button>
                <Popover.Panel className="absolute z-10 bg-white0 p-2 rounded-lg drop-shadow-md flex">
                  <img
                    className="max-h-[200px] max-w-[200px]"
                    src={images && images[0].image_link}
                    alt="uploaded_image"
                  />
                </Popover.Panel>
              </Popover>
            );
          })}
        </div>
      </div>
      <div className="flex justify-center gap-4 mt-5">
        <div className="relative">
          <img src={carFrontImage} alt="car-front" />
          {carFrontStates.map(({ className, images }, index) => {
            return (
              <Popover key={index} className={`absolute ${className}`}>
                <Popover.Button>
                  <div className="bg-[#282F75] w-[10px] h-[10px] rounded-full" />
                </Popover.Button>
                <Popover.Panel className="absolute z-10 bg-white0 p-2 rounded-lg drop-shadow-md w-[200px]">
                  <img
                    className="max-h-[200px] max-w-[200px]"
                    src={images && images[0]?.image_link}
                    alt="uploaded_image"
                  />
                </Popover.Panel>
              </Popover>
            );
          })}
        </div>
        <div className="relative">
          <img src={carLeftImage} alt="car-front" />
          {carLeftStates.map(({ className, images }, index) => {
            return (
              <Popover key={index} className={`absolute ${className}`}>
                <Popover.Button>
                  <div className="bg-[#282F75] w-[10px] h-[10px] rounded-full" />
                </Popover.Button>
                <Popover.Panel className="absolute z-10 bg-white0 p-2 rounded-lg drop-shadow-md w-[200px]">
                  <img
                    className="max-h-[200px] max-w-[200px]"
                    src={images && images[0]?.image_link}
                    alt="uploaded_image"
                  />
                </Popover.Panel>
              </Popover>
            );
          })}
        </div>
      </div>

      <div className="flex justify-center gap-4 mt-5">
        <div className="relative">
          <img src={carBackImage} alt="car-back" />
          {carBackStates.map(({ className, images }, index) => {
            return (
              <Popover key={index} className={`absolute ${className}`}>
                <Popover.Button>
                  <div className="bg-[#282F75] w-[10px] h-[10px] rounded-full" />
                </Popover.Button>
                <Popover.Panel className="absolute z-10 bg-white0 p-2 rounded-lg drop-shadow-md w-[200px]">
                  <img
                    className="max-h-[200px] max-w-[200px]"
                    src={images && images[0]?.image_link}
                    alt="uploaded_image"
                  />
                </Popover.Panel>
              </Popover>
            );
          })}
        </div>
        <div className="relative">
          <img src={carRightImage} alt="car-right" />
          {carRightStates.map(({ className, images }, index) => {
            return (
              <Popover key={index} className={`absolute ${className}`}>
                <Popover.Button>
                  <div className="bg-[#282F75] w-[10px] h-[10px] rounded-full" />
                </Popover.Button>
                <Popover.Panel className="absolute z-10 bg-white0 p-2 rounded-lg drop-shadow-md w-[200px]">
                  <img
                    className="max-h-[200px] max-w-[200px]"
                    src={images && images[0]?.image_link}
                    alt="uploaded_image"
                  />
                </Popover.Panel>
              </Popover>
            );
          })}
        </div>
      </div>
    </>
  );
};
