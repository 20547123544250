import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCar } from "../../../Redux/cars/actions";
import { CarSelect } from "./subComponents/carSelect";
import DropdownWithSearch from "./subComponents/dropDown";
function FormInformation({ id }) {
  const dispatch = useDispatch();
  const carCount = useSelector((state) => state).cars.totalCarsCount;
  const formInformation = useSelector((state) => state).formReducer;
  const [errors, setErrors] = useState({});
  const onChange = (e) => {
    const { name, value } = e.target;

    dispatch({
      type: "ADD_CAR_NUMBER",
      adNumber: carCount,
    });
    dispatch({ type: "UPDATE_FORM", payload: { name, value } });
  };

  const onChangeCarModel = (field, option) => {
    dispatch({
      type: "ADD_CAR_NUMBER",
      adNumber: carCount,
    });
    dispatch({ type: "UPDATE_FORM", payload: { name: field, value: option } });
  };

  const onChangeFunctionalities = (values) => {
    dispatch({
      type: "ADD_CAR_NUMBER",
      adNumber: carCount,
    });
    dispatch({
      type: "UPDATE_FORM",
      payload: { name: "functionalities", value: values },
    });
  };

  const onValidate = (e) => {
    const { name, value } = e.target;

    // overviewYear: This seems to be the year of the car's make. The range 1870 to 2350 is pretty wide. The first practical car was made in the late 1800s (around 1885-1886), so 1870 as a lower limit seems a little early. For the upper limit, predicting beyond the current year might be a stretch, but it's up to you to determine how far into the future you want to allow.

    // km, seat, and price: The code is checking if the value is 0 or negative, which doesn't make sense for these fields. Additionally, for seat, it checks if the number of seats is greater than 12, which is a bit arbitrary but might be okay if you're trying to exclude buses or larger vehicles.

    // engineDisplacement: The range is from 0 to 10,000 cc. While most cars have an engine displacement far less than 10,000 cc, it's a reasonable upper limit if you want to include supercars and some larger vehicles.

    // numberCV: It seems to be validating a value between 0 and 100, which might be the car's horsepower. If so, 100 as an upper limit is very low for modern cars. Many cars, especially sports cars, have horsepower well above 100.

    if (!value.trim()) {
      setErrors((prev) => ({ ...prev, [name]: `Champ requis` }));
      return;
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
    if (
      name === "overviewYear" &&
      (+value < 1885 || +value > new Date().getFullYear() + 10)
    ) {
      dispatch({ type: "UPDATE_FORM", payload: { name, value: "" } });
    }

    if (name === "km" || name === "price") {
      if (+value <= 0) {
        dispatch({ type: "UPDATE_FORM", payload: { name, value: "" } });
      }
    }

    if (name === "seat" && (+value <= 0 || +value > 12)) {
      dispatch({ type: "UPDATE_FORM", payload: { name, value: "" } });
    }

    if (name === "engineDisplacement" && (+value <= 0 || +value > 10000)) {
      dispatch({ type: "UPDATE_FORM", payload: { name, value: "" } });
    }

    // Assuming numberCV is horsepower, increasing the upper limit to a more reasonable number like 2000.
    if (name === "numberCV" && (+value <= 0 || +value > 2000)) {
      dispatch({ type: "UPDATE_FORM", payload: { name, value: "" } });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCar(id));
    } else {
      dispatch({ type: "CLEARSTORE", payload: "ABORTED" });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <form className=" relative space-y-2 py-5  ">
      <div className="flex flex-col items-start  xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">Marque </p>
          <CarSelect
            value={formInformation?.make}
            onChange={onChangeCarModel}
            field="make"
          />
        </div>
        {errors.make && (
          <p className="text-[#f85151] text-[12px]">{errors.make}</p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">Model </p>
          <CarSelect
            value={formInformation?.model}
            make={formInformation?.make}
            onChange={onChangeCarModel}
            field="model"
          />
        </div>
        {errors.model && (
          <p className="text-[#f85151] text-[12px]">{errors.model}</p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">Prix</p>
          <input
            type="number"
            name="price"
            min="0"
            className="box box-border w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
            onBlur={onValidate}
            value={formInformation?.price}
          />
        </div>
        {errors.price && (
          <p className="text-[#f85151] text-[12px]">{errors.price}</p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">Année</p>
          <input
            type="number"
            name="overviewYear"
            min="1900"
            max="2022"
            className="box box-border w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
            onBlur={onValidate}
            value={formInformation?.overviewYear}
          />
        </div>
        {errors.overviewYear && (
          <p className="text-[#f85151] text-[12px]">{errors.overviewYear}</p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">Ville/Région</p>
          <input
            type="text"
            name="location"
            className="box box-border w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
            value={formInformation?.location}
            onBlur={onValidate}
          />
        </div>
        {errors.location && (
          <p className="text-[#f85151] text-[12px]">{errors.location}</p>
        )}
      </div>

      <div className="flex items-center justify-between my-1 xs:w-[300px]  md:w-auto m-auto">
        <p className="font-bold text-[20px] xs:w-[300px]md:w-auto text-lightblue2">
          Aperçu
        </p>
      </div>

      <div className="flex flex-col items-start justify-between xl:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center max-w-[100px]">
            Kilométrage
          </p>
          <input
            type="number"
            name="km"
            min="0"
            className="box box-border w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
            onBlur={onValidate}
            value={formInformation?.km}
          />
        </div>
        {errors.km && <p className="text-[#f85151] text-[12px]">{errors.km}</p>}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="w-auto text-[14px] font-normal self-center max-w-[100px]">
            Nombre de propriétaires
          </p>
          <input
            type="number"
            name="seat"
            min="0"
            max="12"
            onBlur={onValidate}
            className="box box-border w-[205px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
            value={formInformation?.seat}
          />
        </div>
        {errors.seat && (
          <p className="text-[#f85151] text-[12px]">{errors.seat}</p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">
            Boîte de vitesse
          </p>
          <select
            value={formInformation?.gearBox}
            name="gearBox"
            className="box box-border px-5 py-2 w-[150px] xs:w-[205px]  border-2 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
          >
            <option value=""></option>
            <option value="Automatique">Automatique </option>
            <option value="Manuelle">Manuelle </option>
          </select>
        </div>
        {errors.gearBox && (
          <p className="text-[#f85151] text-[12px]">{errors.gearBox}</p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">Énergie</p>
          <select
            value={formInformation?.fuel}
            name="fuel"
            className="box box-border px-5 py-2 w-[150px] xs:w-[205px]  border-2 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
          >
            <option value=""></option>
            <option value="Gasoline">De l'essence </option>
            <option value="Kerosene">Diesel </option>
            <option value="Electric">Électrique </option>
            <option value="Hybrid">Hybride </option>
            <option value="Plug-In Hybrid">Hybride rechargeable </option>
          </select>
        </div>
        {errors.fuel && (
          <p className="text-[#f85151] text-[12px]">{errors.fuel}</p>
        )}
      </div>

      <div className="flex items-center justify-between my-1 xs:w-[300px]  md:w-auto m-auto">
        <p className="font-bold text-[20px] xs:w-[300px]md:w-auto text-lightblue2">
          Spécifications
        </p>
      </div>

      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center max-w-[100px]">
            Cylindrée (cm3/tr)
          </p>
          <input
            value={formInformation?.engineDisplacement}
            type="number"
            min={0}
            max={10000}
            name="engineDisplacement"
            className="box box-border w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
            onBlur={onValidate}
          />
        </div>
        {errors.engineDisplacement && (
          <p className="text-[#f85151] text-[12px]">
            {errors.engineDisplacement}
          </p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px] md:grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <div>
            <p className="text-[14px] font-normal self-center ">
              Couleur extérieure
            </p>
          </div>
          <input
            value={formInformation?.outside}
            type="text"
            name="outside"
            max="12"
            className="box box-border w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
          />
        </div>
        {errors.outside && (
          <p className="text-[#f85151] text-[12px]">{errors.outside}</p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">
            Couleur intérieure{" "}
          </p>
          <input
            value={formInformation?.inside}
            type="text"
            name="inside"
            max="12"
            className="box box-border w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
          />
        </div>
        {errors.inside && (
          <p className="text-[#f85151] text-[12px]">{errors.inside}</p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">
            Puissance fiscale{" "}
          </p>
          <input
            value={formInformation?.numberCV}
            type="number"
            name="numberCV"
            min={0}
            max={100}
            className="box box-border w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
            onBlur={onValidate}
          />
        </div>
        {errors.numberCV && (
          <p className="text-[#f85151] text-[12px]">{errors.numberCV}</p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center">
            Consommation mixte (l/100km)
          </p>
          <input
            value={formInformation?.averageConsumption}
            type="text"
            name="averageConsumption"
            className="box box-border  w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
          />
        </div>
        {errors.averageConsumption && (
          <p className="text-[#f85151] text-[12px]">
            {errors.averageConsumption}
          </p>
        )}
      </div>
      <div className="flex flex-col items-start justify-between xs:w-[300px] md:w-auto m-auto">
        <div className="grid grid-cols-[100px_200px]  xs:w-[300px] md:w-auto m-auto justify-between ">
          <p className="text-[14px] font-normal self-center"> Immatricule</p>
          <input
            value={formInformation?.immatricule}
            type="text"
            name="immatricule"
            className="box box-border w-[150px] xs:w-[205px] h-[42px] border-2 p-5 rounded-lg border-[#F0F1F7]"
            onChange={(e) => onChange(e)}
          />
        </div>
        {errors.immatricule && (
          <p className="text-[#f85151] text-[12px]">{errors.immatricule}</p>
        )}
      </div>
      <div className="flex items-center justify-between  xs:w-[300px]  md:w-auto m-auto">
        <p className="font-bold text-[20px] xs:w-[300px]md:w-auto text-lightblue2">
          Fonctionnalités
        </p>
      </div>
      {/* To recover later */}
      <div className="flex items-center justify-between  xs:w-[300px] md:w-auto m-auto pb-10">
        <div className="relative w-screen ">
          <DropdownWithSearch
            value={formInformation?.functionalities}
            onChange={onChangeFunctionalities}
            onBlur={onValidate}
          />
        </div>
      </div>
    </form>
  );
}

export default FormInformation;
