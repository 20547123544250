import React from "react";
import { ImageGalleryCarousel } from "./components/ImageGalleryCarousel";
import Road from "./svg/Road";
import Seat from "./svg/Seat";
import Gear from "./svg/Gear";
import Fuel from "./svg/Fuel";

const images = [
  {
    link: "./preview-example/image1.png",
  },
  {
    link: "./preview-example/image2.png",
  },
  {
    link: "./preview-example/image1.png",
  },
  {
    link: "./preview-example/image1.png",
  },
  {
    link: "./preview-example/image1.png",
  },
];
const options = [
  {
    icon: <Road />,
    name: "250 KM",
  },
  {
    icon: <Seat />,
    name: "6 Seats",
  },
  {
    icon: <Gear />,
    name: "Automatic",
  },
  {
    icon: <Fuel />,
    name: "Petrol",
  },
];
const specifications = [
  {
    name: "Year",
    parameter: "2003",
  },
  {
    name: "Extérieur",
    parameter: "Black",
  },
  {
    name: "Intérieur",
    parameter: "Red",
  },
  {
    name: "Nombre de CV",
    parameter: "5 CV",
  },
];
function PreviewCar() {
  const [active, setActive] = React.useState("./preview-example/image1.png");

  return (
    <div className="w-full min-h-[500px] flex flex-col bg-white p-4">
      <div className="w-full mb-2">
        <button className="bg-lightblue2 rounded-xl flex justify-center px-20 py-2 items-center  text-sm float-right">
          <span className="text-white ">Back to Car Table</span>
        </button>
      </div>
      <div className="flex flex-col gap-1 overflow-y-auto px-10 xl:px-20 h-[500px]">
        <h1 className="text-black font-bold text-xl mb-4">BMW X2 2021</h1>
        <h1 className="text-darkblue1 font-bold text-xxl mb-4">28 400 €</h1>
        <div className="flex ">
          <span>Location : </span>
          <span>Paris</span>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full">
            <ImageGalleryCarousel
              active={active}
              setActive={setActive}
              images={images}
            />
          </div>
        </div>
        <div className="flex flex-col xl:flex-row  justify-between gap-10 mt-2">
          <div className="bg-white0 w-full h:fit xl:h-full mx-auto xl:w-[250px] flex-shrink-0 sm:mb-4 md:mb-0 sm:drop-shadow-lg rounded-md md:border md:border-grey2 p-6 overflow-hidden">
            <h3 className="text-lightblue2 font-bold mb-5">Overview</h3>
            <div className="flex flex-col gap-4 ">
              {options.map(({ icon, name }, index) => (
                <div key={index} className="flex gap-4 items-center ">
                  {icon}
                  <span>{name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="max-h-full h-fit xl:flex bg-white0 lg:rounded-md min-w-0 flex-grow sm:drop-shadow-lg rounded-md md:border md:border-grey2 flex-col p-6">
            <h3 className="text-lightblue2 font-bold mb-5">Specifications</h3>
            <div className="flex flex-col gap-4 w-[380px]">
              {specifications.map(({ name, parameter }, index) => (
                <div key={index} className="flex justify-between">
                  <span className="text-lightblue2">{name}</span>
                  <span>{parameter}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewCar;
